import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import './ArgosHoustonWeeklyReportDetailer.scss';
import {format} from 'date-fns';
import {Img} from '../../reusableComponents/ImageGallery/Img';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {MarkAsCompletedPopOver} from '../../reusableComponents/MarkAsCompletePopOver';
import {saveFlagCompletions} from '../../../services/dailyFormService';
import {calculateActualTitleDate, convertTime, getAnswerWithFlag} from '../../../utils/generalUtils'; 
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';

export interface IArgosHoustonReportDetailerState {
    formData: any;
    signBase64: string;
}

export interface IArgosHoustonReportDetailerProps {
    formData: any;
    onFlagUpdate: () => void;
    onopenInNewViewTab: Function;
}

export class ArgosHoustonReportDetailerImpl extends React.Component<IArgosHoustonReportDetailerProps, IArgosHoustonReportDetailerState> { 
    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');
    listOfNWQuestions: Array<any> = [];
    questionCount = 0;

    constructor(props: IArgosHoustonReportDetailerProps) {
        super(props); 
        this.state = {
            signBase64: '',
            formData: props.formData || {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.forceUpdate();
        this.setState({
            signBase64: '',
            formData: nextProps.formData || {}
        });
    }

    renderTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="main-title">
                <td colSpan={4}>{quest.question}</td>
            </tr>
        );
    } 
    renderSecondaryTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="secondary-title">
                <td colSpan={4}> {quest.question}</td> 
            </tr>
        );
    }
    renderParagraph = (quest: any) => {
        let ques = quest.question.split('~')
        return (
            <div className="question-row">
                <div key={quest.questionId} className="field-paragraph"> 
                    {
                        (ques || []).map( (item) => {
                            return <p dangerouslySetInnerHTML={{__html: item}}
                            />
                        })
                    }
                </div> 
            </div>
        )
    }  
    getQuestionCount = (questData) => {
        return (questData && questData.question && (questData.questionType !== 'Title' && questData.questionType !== 'Subtitle')) ? this.questionCount += 1 : null;
    } 
    renderObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => {
        const questionNo = this.getQuestionCount(quest);
        if (quest.answer === 'Needs Work') {
            this.listOfNWQuestions.push(questionNo + ') Needs work - ' + quest.subQuestions[0].answer);
        }
        return (
            <React.Fragment key={quest.questionId}>
                <tr className="question-row">
                    <td className="question">
                        {questionNo + ') ' + quest.question}
                    </td>
                    <td className="answer">
                        <span className="pp-printable-data">{quest.answer === 'Needs Work' ? <span className="fa fa-check"/> : <span/>}</span>
                        <span className="d-print-none">{quest.answer === 'Needs Work' ? quest.subQuestions[0].answer : <span/>}</span>
                    </td>
                    <td>
                        {quest.answer === 'Ok' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                    <td>
                        {quest.answer === 'N/A' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                </tr> 
            </React.Fragment>
        );
    } 
    renderComments = (quest ) => {
        return <div className="question-row" key={quest.questionId}>
            <div className={'question'}>
                {quest.question}
            </div>
            <div className={'answer'}>{quest.answer || 'Unanswered'}</div>
        </div>;
    } 
    renderDate = (quest, questNo, isSubQuest) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? format(new Date(quest.answer), 'MM-dd-yyyy') : 'Unanswered'}</div>
            </div>
        );
    } 
    generateQuestionAnswer = (quest, questNo, isSubQuest?: boolean) => {
        if (quest.questionType) {
            quest.questionType = quest.questionType.trim();
        }
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': case 'Hint': {
                return this.renderSecondaryTitles(quest);
            }
            case 'Paragraph':
                return this.renderParagraph(quest);
            case 'Comments':
                return this.renderComments(quest);
            case 'ObjectiveType': { 
                return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest); 
            } 
        }
    }
    renderReports() {
        const { parentInspectionName } = this.reportsMetaData 
        const {questionAndAnswer } = this.props.formData;
        if(questionAndAnswer) {
            switch(parentInspectionName) {
                case 'Weekly Form RM248':
                    return this.renderArgosWeeklyDustCollector();  
                case 'Weekly Dust Collector and Fuel Area904' :
                    return this.renderArgosWeeklyDustCollector();
            }
        }
    } 
    renderArgosWeeklyDustCollector() {
        const {questionAndAnswer, plantId, submittedDate, submittedBy } = this.props.formData;
        return (
            <>
            <div className='Argos-weekly-report-table-container' style={{paddingTop: '15px'}}> 
                <div className='inspection-basic-details'>
                    <Row>
                        <Col sm={12} lg={12}>Plant Name/#: {plantId} <span style={{paddingLeft: '20px'}}>Date: {submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span> <span style={{paddingLeft: '20px'}}>Inspected By: {submittedBy || 'NA'}</span></Col> 
                    </Row>
                </div>
                <table className='ArgosWeeklyDustCollector'>
                    <thead>
                    <tr>
                        <th colSpan={12} className="table-col-tabtitle">Dust Collector</th>
                    </tr> 
                    <tr> 
                        <th className="table-col-unit">Unit</th>
                        <th className="table-col-unit1">1</th>
                        <th className="table-col-unit2">2</th> 
                        <th className="table-col-repairact">Repair/Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderDustCollectorAnswers(1)}
                        {this.renderDustCollectorAnswers(5)}
                        {this.renderDustCollectorAnswers(9)}
                        {this.renderDustCollectorAnswers(13)}
                        {this.renderDustCollectorAnswers(17)} 
                        <tr>
                            <td colSpan={12} className="table-col-insArea">Comments: {questionAndAnswer[21] && questionAndAnswer[21]['answer']}</td>
                        </tr>
                    </tbody>
                </table>
                <div className='inspection-basic-details mt20'>
                    <Row> 
                        <Col sm={6}  ><span>Date: {submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span><span style={{paddingLeft: '20px'}}>Inspected By: {submittedBy || 'NA'}</span> </Col> 
                    </Row>
                </div>
                <table className='ArgosWeeklyDustCollector mt10'>
                    <thead>
                    <tr>
                        <th colSpan={12} className="table-col-tabtitle">Fuel Area</th>
                    </tr> 
                    <tr> 
                        <th className="table-col-unit"></th>
                        <th className="table-col-fuelQuesYes">Yes</th>
                        <th className="table-col-fuelQuesNo">No</th> 
                        <th className="table-col-fuelQuesNA">N/A</th> 
                        <th className="table-col-fuelRepairAct">Repair/Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderFuelAreaAnswers(23)} 
                        {this.renderFuelAreaAnswers(25)} 
                        {this.renderFuelAreaAnswers(27)} 
                        {this.renderFuelAreaAnswers(29)} 
                        {this.renderFuelAreaAnswers(31)} 
                        {this.renderFuelAreaAnswers(33)} 
                        {this.renderFuelAreaAnswers(35)} 
                        {this.renderFuelAreaAnswers(37)} 
                        {this.renderFuelAreaAnswers(39)} 
                        {this.renderFuelAreaAnswers(41)} 
                        <tr>
                            <td colSpan={12} className="table-col-insArea">Comments: {questionAndAnswer[43] && questionAndAnswer[43]['answer']}</td>
                        </tr>
                    </tbody>
                </table> 
            </div></>
        );
    }
    renderFuelAreaAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        let answer = questionAndAnswer[index] && questionAndAnswer[index]['answer'];
        return (
            <tr>
                <td className="table-col-unit">{questionAndAnswer[index] && questionAndAnswer[index]['question']}</td> 
                <td className="table-col-fuelQuesYes fuelAnswer">{answer==='Yes' && <i className="fa fa-check-square"></i>}</td>
                <td className="table-col-fuelQuesNo fuelAnswer">{answer==='No' && <i className="fa fa-check-square"></i>}</td> 
                <td className="table-col-fuelQuesNA fuelAnswer">{answer==='N/A' && <i className="fa fa-check-square"></i>}</td> 
                <td className="table-col-fuelRepairAct">{this.renderAnswer(index+1)}</td> 
            </tr>
        )
    }
    renderDustCollectorAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className="table-col-unit">{questionAndAnswer[index] && questionAndAnswer[index]['question']}</td>
                <td className="table-col-unit1">{this.renderAnswer(index+1)}</td>
                <td className="table-col-unit2">{this.renderAnswer(index+2)}</td> 
                <td className="table-col-repairact">{questionAndAnswer[index+3] && questionAndAnswer[index+3]['answer']}</td>
            </tr>
        )
    }
    
    renderImages = () => {
        const {ImageUrls, SignatureUrls, isDeclarationRequired} = this.props.formData;
        const {onopenInNewViewTab} = this.props;

        return <div className="declarations" key={Date.now()}>
            <hr className="line-break"/>
            <div className="images-container">
                <div className="image-label">Attachments :</div>
                <div className="images-list">
                    {ImageUrls && ImageUrls.length > 0 ? ImageUrls.map((url, index) => {
                        let fileDet = url.fileName.split('.');  
                        const fileExtension = fileDet[fileDet.length - 1];
                        let imageThumb = '';
                        let fileFormat = 'NotPDF';
                        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
                        switch(fileExtension.toLowerCase()) {
                            case 'pdf': imageThumb = pdfThumb; fileFormat = 'PDF'; break;
                            case 'doc': case 'docx': imageThumb = wordThumb; break; 
                            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                            case 'txt': imageThumb = textThumb; break;
                            case 'mp4': imageThumb = videoThumb; break; 
                            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = url.imageURL; break;
                            // default: imageThumb = ''; this.invalidFile++; break; 
                            default: imageThumb = ''; break; 
                        } 
                        return <div
                            key={index}
                            title="Click to view in full screen mode."
                            className="image">
                                {fileFormat == "PDF" ?
                                    <span onClick={() => onopenInNewViewTab(url.imageURL)} >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></span>
                                : 
                                    <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a>
                                }
                        </div>;
                        // return <div
                        //     key={index}
                        //     title="Click to view in full screen mode.."
                        //     className="image">
                        //     <Img src={url.imageURL} onClick={() => showCarousel(url.imageURL)}/>
                        // </div>;
                    }) : <div
                        style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                        No Attachments to this form.
                    </div>
                    }
                </div>
            </div> 
        </div>;
    }

    renderAnswer(index) { 
        const {questionAndAnswer } = this.props.formData;
        let quest = questionAndAnswer[index];
        if(questionAndAnswer.length>index) { 
            let answer = questionAndAnswer[index]['answer'];
            let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(quest);
            return (
                quest.isFlagged && quest.followUpOn===quest.answer ? <div className="flagged-answer"> <MarkAsCompletedPopOver ref="popOver" formSubmittedDate={this.reportsMetaData.submittedDate} questionId={quest.questionId} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue}>
                        <div className={`${followUpClass} mark-as-complete-col`}
                            title="Click to enter the follow-up comment">
                            <i className={followUpLogo} aria-hidden="true"> </i> {answer}
                        </div> 
                </MarkAsCompletedPopOver> </div> : answer
            )
        }   
    }
    handleFlagAnswerUpdate = async (comments, date, questionId) => {
        let inspId = this.props.formData.inspectionUId;

        await saveFlagCompletions(inspId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'),undefined,'ArgosHoustonReportDetailer').then((res) => {
            if (this.props.onFlagUpdate) {
                this.props.onFlagUpdate();
            }
        });
    }
    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, PsName, lastUpdatedDate, isPermanent} = this.props.formData;
        const {inspectionName, frequency, actualDate, facilityType, permitType} = this.reportsMetaData; 
        return <div style={{padding: '20px'}} id="details-page" className="reports-page-container">
            <div className="report-title">{`${inspectionName} - ${calculateActualTitleDate(frequency, actualDate)}`}</div>
            <div className="pp-printable-data">
                <div className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>
                <div className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span></div>
                <div className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </div>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>

            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                {this.renderReports()}
            </Row>
            {this.renderImages()} 
        </div>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const ArgosHoustonReportDetailer = connect<{}, IArgosHoustonReportDetailerState, IArgosHoustonReportDetailerProps>(mapStateToProps)(ArgosHoustonReportDetailerImpl as any);
 
