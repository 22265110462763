import {format} from 'date-fns';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {IDailyForms, IHistory} from '../../../interfaces';
import {getDailyFormData} from '../../../services/weeklyFormService';
import {getCurentDate, splitDate} from '../../../utils/generalUtils';
import {BasePage} from '../../reusableComponents/BasePage';
import {RWeeklyPicker} from '../../reusableComponents/WeekPicker';
import {ListPage} from '../../reusableComponents/ListPage';
import {RegionFilter} from '../../reusableComponents/RegionFilter';
import {ListItem} from '../InspectionReportDetailer/ListItem';
import { getRegionsByOrg } from '../../../services/orgService';
import { getUserData } from '../../../services/loginService';
import { RegionModel } from '../../../model/OrgModel';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { Pagination } from '../../reusableComponents/Pagination';

const queryString = require('query-string');

export interface IWeeklyReportsProps {
    dailyForms?: IDailyForms; 
    history?: IHistory;
    location?: any;
    axiosPreviousToken: any;
    regionList: [];
}

export interface IWeeklyReportsState {
    date: Date;
    data: Array<any>;
    regionName: string;
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
    totCount: any;
}

export class WeeklyReportsImpl extends React.Component<IWeeklyReportsProps, IWeeklyReportsState> {
    loggedInUserInfo = getUserData();
    constructor(props: IWeeklyReportsProps) {
        super(props);
        //let date = new Date();
        let date = moment();
        let region = 'All';
        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
        }
        //this.state = {data: [], date: new Date(date.toString().replace(/-/g, '/')), regionName: region};
        this.state = {
            data: [], 
            date: getCurentDate(date, 'MM/DD/yyyy', true), 
            regionName: region,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            searchText:'',
            totCount:0
        };
    }

    promise = async () => { 
        //let date = format(new Date(), 'yyyy-MM-dd');
        let date = getCurentDate(moment(), 'yyyy-MM-DD');
        let region = 'All';
        if (this.props.location.search) {
            date = queryString.parse(this.props.location.search).date;
            region = queryString.parse(this.props.location.search).region;
        }
        const {mm, dd, yyyy} = splitDate(date);
        await getDailyFormData(mm, dd, yyyy, region).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        }
    }

    handleDateFilter = async (e: any) => {
        await this.setState({
            date: e,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
        });
        const {mm, dd, yyyy} = splitDate(format(new Date(e), 'yyyy-MM-dd'));
        setLoading('WeeklyReports');
        const queryString = `?date=${format(e, 'yyyy-MM-dd')}&&region=${this.state.regionName}`;
        await getDailyFormData(mm, dd, yyyy, this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/weekly${queryString}`);
        setSuccess('WeeklyReports');
    }

    handleRegionFilter = async (filterName: string, regionName: string) => {
        await this.setState({
            regionName,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
        });
        const {mm, dd, yyyy} = splitDate(format(new Date(this.state.date), 'yyyy-MM-dd'));
        setLoading('WeeklyReports');
        const queryString = `?date=${format(this.state.date, 'yyyy-MM-dd')}&&region=${regionName}`;
        await getDailyFormData(mm, dd, yyyy, regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/weekly${queryString}`);
        setSuccess('WeeklyReports');
    }

    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            curPageNo: searchText.length > 0 && this.state.searchText == searchText ? this.state.curPageNo : 1,
            searchText: searchText,
        })
        const {mm, dd, yyyy} = splitDate(format(new Date(this.state.date), 'yyyy-MM-dd'));
        
        setLoading('WeeklyReports');

        await getDailyFormData(mm, dd, yyyy, this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });

        setSuccess('WeeklyReports');
    }

    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        const {mm, dd, yyyy} = splitDate(format(new Date(this.state.date), 'yyyy-MM-dd'));
        setLoading('WeeklyReports');
        // const queryString = `?date=${format(this.state.date, 'yyyy-MM-dd')}&&region=${this.state.regionName}`;
        await getDailyFormData(mm, dd, yyyy, this.state.regionName, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content
            });
        });
        setSuccess('WeeklyReports');

    }

    render() {
        let pageno = Math.ceil(this.state.totCount/20 );

        return (
            <BasePage className="list-page-base weekly-reports" pageTitle="Weekly Reports">
                <ListPage
                    // @ts-ignore
                    identifier="WeeklyReports"
                    instances={this.state.data}
                    frequency={'Weekly'}
                    listItemComponent={ListItem}
                    searchBy={['plantId', 'plantName', 'plantRegionName', 'permitType', 'inspectionName' ]}
                    instanceOf={'plantInfo'}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    pageHeadings={['#', 'PlantId', 'Region', 'Facility', 'Permit', 'Form', 'Submitted', 'Last Updated']}
                    listItemFields={['', 'plantId', 'plantRegionName', 'facilityType', 'permitType', 'inspectionName',  'submittedDate', 'lastUpdatedDate']}
                    cols={[1, 1, 2, 1, 1, 2, 2, 2]}
                    searchPlaceHolder="Enter plant id, plant name, region, permit type, inspection to search..."
                    emptyListMessage="No Weekly Reports found"
                    listItemProp={format(this.state.date, 'MM/dd/yyyy')}
                    chartFilter={<div className="filter-container row"> 
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} > 
                            <RWeeklyPicker
                                value={this.state.date}
                                onChange={this.handleDateFilter}
                            />
                        </Col> 
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} > 
                            <RegionFilter
                                selectedValue={this.state.regionName}
                                onChange={this.handleRegionFilter}
                                regionList={this.props.regionList} 
                            />
                        </Col>
                    </div>}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');
    const regionList = RegionModel.list();
    return { regionList, axiosPreviousToken };
}

export const WeeklyReports = withRouter(connect< IWeeklyReportsProps, any, any>(mapStateToProps)(WeeklyReportsImpl as any));
