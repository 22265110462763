import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';   
import {CommonReportDetailer} from './commonReportDetailer';
import {getCommonInspectionFormAnswers} from '../../../services/formService';
import {BasePage} from '../../reusableComponents/BasePage';
import {Async} from '../../reusableComponents/Async';
import {Loader} from '../../reusableComponents/Loader';
import {ErrorPage} from '../../reusableComponents/ErrorPage';
import {calculateActualTitleDate, toDataUrl} from '../../../utils/generalUtils';
import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {PlantPreventiveReportDetailer} from './PlantPreventiveReportDetailer';
import {InBevReportDetailer} from './InBevReportDetailer';
import { FreeseReportDetailer } from './FreeseReportDetailer';
import { ArgosHoustonReportDetailer} from './ArgosHoustonWeeklyReportDetailer';
import { ArgosGeorgiaYearlyMaintenanceReportDetailer} from './ArgosGeorgiaYearlyMaintenanceReport';
import { Gallery } from '../../reusableComponents/ImageGallery';
import { PdfViewer } from '../../reusableComponents/FormComponents/PdfViewer';
import { hidePrintPreviewModal } from '../../../actions/modalActions';
import { Modal } from 'react-bootstrap'; 
import { ArgosHousekeepingReportDetailer } from './ArgosHousekeepingReportDetailer';

export interface IReportDetailsPageState {
    formData: any;
    displayView: boolean;
    pdfUrl: string;
}

export interface IReportsDetailsPageProps {
    frequency: string;
    inspectionId: string;
    formId: string;
    
}

export class ReportsDetailsPageImpl extends React.PureComponent<IReportsDetailsPageProps, IReportDetailsPageState> {

    static defaultProps: IReportsDetailsPageProps;
    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');

    constructor(props: IReportsDetailsPageProps) {
        super(props);
        this.state = {
            formData: {},
            displayView: false,
            pdfUrl: ''

        };
    }

    promise_OLD = async () => {
        setLoading('ReportsDetailsPage');
        await getCommonInspectionFormAnswers(this.reportsMetaData.FormId, this.reportsMetaData.inspectionId,'Flagged Report').then(res => {
            if (res) {
                this.setState({
                    formData: {}
                });
                if (res.SignatureUrls && res.SignatureUrls.length > 0) {
                    toDataUrl(res.SignatureUrls[0].imageURL, (imgData) => {
                        res = JSON.parse(JSON.stringify(res));
                        res.SignatureUrls = imgData;
                        setTimeout(() => {
                            this.setState({
                                formData: res
                            });
                            setSuccess('ReportsDetailsPage');
                        }, 300);
                    });
                } else {
                    setTimeout(() => {
                        this.setState({
                            formData: res
                        });
                        setSuccess('ReportsDetailsPage');
                    }, 300);
                }
            }
        });
    }
    promise = async () => {
        setLoading('ReportsDetailsPage');
        const { frequency, formId, inspectionId } = this.props;
        await getCommonInspectionFormAnswers(formId, inspectionId,'Flagged Report').then(res => {
            if (res) {
                this.setState({
                    formData: {}
                });
                if (res.SignatureUrls && res.SignatureUrls.length > 0) {
                    toDataUrl(res.SignatureUrls[0].imageURL, (imgData) => {
                        res = JSON.parse(JSON.stringify(res));
                        res.SignatureUrls = imgData;
                        setTimeout(() => {
                            this.setState({
                                formData: res
                            });
                            setSuccess('ReportsDetailsPage');
                        }, 300);
                    });
                } else {
                    setTimeout(() => {
                        this.setState({
                            formData: res
                        });
                        setSuccess('ReportsDetailsPage');
                    }, 300);
                }
            }
        });
    }

    printAction = () => {
        window.print()
    }

    renderPrintButton = () => {
        return <div style={{
            position: 'absolute',
            right: '20px',
        }} className="button d-print-none" onClick={this.printAction} id="print-button">
            <i className="fa fa-print" aria-hidden="true"/>
            &nbsp;
            <span>Print</span>
        </div>;
    }

    renderContent_OLD = () => {
        const PlantPreventiveArray = [ 'ReadyMixWaterDailyPlantPreventive','AsphaltWaterDailyPlantPreventive','OthersWaterDailyPlantPreventive','QuarryWaterDailyPlantPreventive']
        return !PlantPreventiveArray.includes(this.state.formData.ParentId) ? <CommonReportDetailer
            onFlagUpdate={this.promise}
            formData={this.state.formData}
        /> : <PlantPreventiveReportDetailer formData={this.state.formData} onFlagUpdate={this.promise}/>;
    }

    renderContent = () => {
        switch(this.state.formData.ParentId) {
            case 'ReadyMixWaterDailyPlantPreventive': case 'AsphaltWaterDailyPlantPreventive': case 'QuarryWaterDailyPlantPreventive': case 'OthersWaterDailyPlantPreventive':  
                return <PlantPreventiveReportDetailer formData={this.state.formData} onFlagUpdate={this.promise} onopenInNewViewTab={this.handlePdfView} />
            case 'InBevVisibleOpacity': case 'InBevBrewingRoofVent': case 'InBevFilterCellerArea': case 'InBevBrewhouseArea': case 'InBevGrainsUploadingArea':
                return <InBevReportDetailer formData={this.state.formData} onFlagUpdate={this.promise} onopenInNewViewTab={this.handlePdfView} /> 
            case 'FreeseOthersWaterQTRVisual':
                return <FreeseReportDetailer formData={this.state.formData} onFlagUpdate={this.promise} onopenInNewViewTab={this.handlePdfView} />
            case 'Weekly Form RM248': case 'Weekly Dust Collector and Fuel Area904':
                return <ArgosHoustonReportDetailer formData={this.state.formData} onFlagUpdate={this.promise} onopenInNewViewTab={this.handlePdfView} />  
            case 'Housekeeping Plant80': case 'Housekeeping Shop80': case 'Daily-Weekly Check (All)80': case 'Daily-Weekly Check (additional)80': case 'Monthly Check (All)80': case 'Yearly Check80':
                return <ArgosHousekeepingReportDetailer formData={this.state.formData} onFlagUpdate={this.promise} onopenInNewViewTab={this.handlePdfView} /> 
            case "Maintenace Record263": case 'Improvements & CAPEX708':
                return <ArgosGeorgiaYearlyMaintenanceReportDetailer formData={this.state.formData} onFlagUpdate={this.promise} onopenInNewViewTab={this.handlePdfView} /> 
            default: 
                return <CommonReportDetailer onFlagUpdate={this.promise} formData={this.state.formData} onopenInNewViewTab={this.handlePdfView} />
        } 
    }

    
    render_OLD() {
        const outfall = this.state.formData.PsName ? ' (' + this.state.formData.PsName + ')' : '';
        const title = `${this.reportsMetaData.inspectionName + outfall} - ${calculateActualTitleDate(this.reportsMetaData.frequency, this.reportsMetaData.actualDate)}`; 
        let imgUrls: any = [];
        if(this.state.formData && this.state.formData.ImageUrls) {
            this.state.formData.ImageUrls.forEach(img => { 
                imgUrls.push(img.imageURL)
                
            });
        } 
        return <BasePage sideButton={this.renderPrintButton()} pageTitle={title}>
            <Gallery images={imgUrls} />
            <Async
                identifier="ReportsDetailsPage"
                promise={this.promise}
                content={this.renderContent()}
                loader={<Loader type="async"/>}
                error={<ErrorPage/>}
            />
        </BasePage>;
    }
    handlePdfView = (Url) => {
        this.setState({
            displayView: true,
            pdfUrl: Url
        //     downloadPdf: false
        })
    }
    hidePreviewModal = () => {
        this.setState({
            displayView: false,
        })
        hidePrintPreviewModal();
    }

    render() {
        const {frequency} = this.props;
        const {formData} = this.state;
        let title = 'Report Details'; 
        if(Object.keys(formData).length !== 0 && formData.inspectionDate ) {
            const outfall = formData.PsName ? ' (' + formData.PsName + ')' : '';
            title = `${formData.inspectionName + outfall} - ${calculateActualTitleDate(frequency, formData.inspectionDate)}`; 
        }
        return <BasePage sideButton={this.renderPrintButton()} pageTitle={`${title}`}>
            {this.state.displayView === true ?
                <Modal size="lg" className="print-preview-outer" show={true} onHide={() => this.hidePreviewModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: 'white' }}>Reference Document. </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ color: 'black', backgroundColor: 'white' }}>
                        <PdfViewer 
                            htmlFor={this.state.pdfUrl} 
                        />
                    </Modal.Body>
                </Modal > 
            : ''}
             <Async
                identifier="ReportsDetailsPage"
                promise={this.promise}
                content={this.renderContent()}
                loader={<Loader type="async"/>}
                error={<ErrorPage/>}
            />
        </BasePage>
    }
}

export function mapStateToProps(state, ownProps) {
    const frequency = ownProps.match.params.frequency;
    const inspectionId = ownProps.match.params.inspectionId;
    const formId = ownProps.match.params.formId;
    return { frequency, inspectionId, formId };
}

export const ReportsDetailsPage = connect<{}, IReportDetailsPageState, IReportsDetailsPageProps>(mapStateToProps)(ReportsDetailsPageImpl as any);
