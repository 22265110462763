import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'; 
import {DropdownButton, Dropdown, OverlayTrigger, Popover, PopoverContent, PopoverTitle, Col, Row, } from 'react-bootstrap'; 
import {IHistory} from '../../../interfaces'; 
import {BasePage} from '../../reusableComponents/BasePage'; 
import {parseJwt} from "../../../services/loginService";
import {Form} from '../../reusableComponents/FormComponents/Form';
import './questionSetupStyle.scss';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { questionSetUp, getCommonFormQuestions, deleteInspectionForms, updateInspectionFormName } from '../../../services/formService';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'; 
import { Async } from '../../reusableComponents/Async';
import {Loader} from '../../reusableComponents/Loader'; 
import {ErrorPage} from '../../reusableComponents/ErrorPage';
import { toast } from 'react-toastify';
import { isNumeric } from '../../../utils/generalUtils';
/* import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'; */
import { hidePrintPreviewModal } from '../../../actions/modalActions';
import { Modal } from 'react-bootstrap'; 
import Dialog from 'react-bootstrap-dialog';



export interface IQuestionSetupProps {
    history?: IHistory;
    formId: string;
    formName: string;
    userAccess_Level: string;
    permitType: string;
}

export interface IQuestionSetupState {
    insQues: any;  
    isLoading: boolean;
    quesRearrange: any;
    deletedComponents: any;
    displayView: boolean;
    inspectionName: string;
}
  
export class QuestionSetupImpl extends React.PureComponent<IQuestionSetupProps, IQuestionSetupState> {
    dialog: Dialog;

    constructor(props: IQuestionSetupProps) {
        super(props);
        this.state = { insQues: [], isLoading: false, quesRearrange: { dragStart: '', dragTo: '', showPopoverOptions: '', showPopoverPosition: '' }, deletedComponents: [], displayView: false, inspectionName: ''};
    }
    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }
    currentQuesNo: number = 0;
    promise = async () => {
        const { formId } =this.props;
        await getCommonFormQuestions(formId, '3').then(res => { 
            if(res) {
                this.setState({ insQues: res });
            } 
        });
    }
    handleCancel = () => {
        this.props.history?.push('/inspections');
    } 
    onSubmitForm = async () => {  
        const {history, formId, permitType} = this.props;
         let data = {
            quesData: this.state.insQues,
            permitType,
            formId: formId, deletedComponents: this.state.deletedComponents, 
        } 
        await this.setState({isLoading: true})
        await questionSetUp(data, history) 
        await this.setState({isLoading: false}) 
        return;
    }
    addNewComponent = async (type, quesTo: any = '', parentQues: any = '') => {
        const { insQues } = this.state;
        let length = insQues.length || 0
        let newques: any = {
            'index': length, 
            'questionType': type, 'question': '',
            'choices': [],
            'priority': length + 1,
            'subQuestions': [], 
            'questionId': '', 'isFlagged': false, 'followUpOn': ''
        } 
        if(quesTo==='') {
            let newState = insQues.concat(newques)
            await this.setState({insQues: newState});  
        } else {
            const insQues = [...this.state.insQues];
            let subQuestions = insQues[parentQues['index']].subQuestions;
            let length = subQuestions.length || 0
            newques.index = length;
            newques.answerSelected = quesTo;
            insQues[parentQues['index']].answertoSQ = quesTo;
            insQues[parentQues['index']].answerSelected = quesTo; 
            insQues[parentQues['index']].subQuestions.push(newques); 
            await this.setState({insQues});  
        }
    }
    createNewQuestion = () => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Question Type</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button' onClick={() => this.addNewComponent('Text')} ><button >Text</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Comments')} ><button >Textarea</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Date')} ><button >Date</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Time')} ><button >Time</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('ObjectiveType')} ><button >Choices</button></div>
                </div> 
            </PopoverContent>
        </Popover>;
    } 
    createNewComponent = () => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">New Component</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button' onClick={() => this.addNewComponent('Title')} ><button >Title</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Subtitle')} ><button >SubTitle</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Hint')} ><button >Notes</button></div>
                    <div className='action-button'>
                        <span><OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="right"
                            overlay={ this.createNewQuestion() }
                        >
                            <span><button >Question</button></span>
                        </OverlayTrigger></span> 
                        </div> 
                </div> 
            </PopoverContent>
        </Popover>;
    } 
    handleDragStart = (e, index) => { 
        let rearrange = this.state.quesRearrange; 
        if(rearrange['dragStart']==='') {
            rearrange['dragStart'] = index; 
            this.setState({quesRearrange: rearrange}); 
        }
    }; 
    handleDragEnter = (e, index) => {
        /* e.target.style.backgroundColor = "#336699"; */  
        //e.currentTarget.className += " rearrange-component-ondrag"; 
    }; 
    handleDragLeave = (e, index) => {
        /* e.target.style.backgroundColor = "black"; */  
    }; 
    handleDrop = (e, index) => {
        /* e.target.style.backgroundColor = "black"; */ 
        let rearrange = this.state.quesRearrange; 
        if(rearrange['dragStart']!=='' && (index != rearrange['dragStart']) ) {
            let insQues = [...this.state.insQues]; 
            if (index.toString().indexOf("_") > -1) {
                if(rearrange['dragStart'].toString().indexOf("_") < 0) {
                    rearrange['dragStart'] = ''; 
                    this.setState({quesRearrange: rearrange}); 
                    toast.error('Main question can\'t be move to sub-question. Please delete main question and add as sub-question', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'failed-toast'
                    }); 
                    return false;
                } else {
                    let dragToIndexWithParentIndex = index.split("_");
                    //insQues[indexWithParentIndex[0]].subQuestions.splice(indexWithParentIndex[1],1) 
                    let dragFromIndexWithParentIndex = rearrange['dragStart'].split("_");
                    let dragItem = insQues[dragFromIndexWithParentIndex[0]].subQuestions[dragFromIndexWithParentIndex[1]];
                    insQues[dragFromIndexWithParentIndex[0]].subQuestions.splice(dragFromIndexWithParentIndex[1], 1); 
                    insQues[dragToIndexWithParentIndex[0]].subQuestions.splice(dragToIndexWithParentIndex[1], 0, dragItem); 
                }
            } else {
                let dragItem: any;
                if (rearrange['dragStart'].toString().indexOf("_") > -1) {
                    let dragFromIndexWithParentIndex = rearrange['dragStart'].split("_");
                    dragItem = insQues[dragFromIndexWithParentIndex[0]].subQuestions[dragFromIndexWithParentIndex[1]];
                    insQues[dragFromIndexWithParentIndex[0]].subQuestions.splice(dragFromIndexWithParentIndex[1], 1); 
                    dragItem['answertoSQ'] = '';
                } else { 
                    dragItem = insQues[rearrange['dragStart']]
                    insQues.splice(rearrange['dragStart'], 1); 
                }
                insQues.splice(index, 0, dragItem);
            } 
            rearrange['dragStart'] = ''; 
            this.setState({quesRearrange: rearrange}); 
            this.reArrangeComponent(insQues);  
            toast.success('Priority changed successfully', { position: toast.POSITION.TOP_RIGHT, autoClose: 1000, }); 
        }
        //e.classList.remove("rearrange-component-ondrag"); 
    };
    renderComponents() {  
        this.currentQuesNo = 0; 
        if(this.state.insQues.length>0) {
            return this.state.insQues.map((ques, index) => {
                return <div draggable onDragStart={(e) => this.handleDragStart(e, ques.index)}
                onDragEnter={(e) => this.handleDragEnter(e, ques.index)}
                onDragLeave={(e) => this.handleDragLeave(e, ques.index)}
                onDrop={(e) => this.handleDrop(e, ques.index, )}
                onDragOver={(e) => e.preventDefault()} ><div className="drag-icon"><i className="fa fa-bars"></i></div> { this.renderComponentFormat(ques, index) } </div>; 
            }); 
        }
    }
    renderComponentFormat = (ques, index) => {
        document.body.click();  
        ques.index = index; 
        if (ques.questionType === 'Title') {
            return this.renderTitleComponent(ques);
        } else if (ques.questionType === 'Subtitle') {
            return this.renderSubTitleComponent(ques);
        } else if (ques.questionType === 'Hint') {
            return this.renderHintTextAreaComponent(ques);
        } else if (ques.questionType === 'Text' || ques.questionType === 'BlockScore' || ques.questionType === 'TotalScore') {
            return this.renderTextComponent(ques);
        } else if (ques.questionType === 'Comments') {
            return this.renderTextAreaComponent(ques);
        } else if (ques.questionType === 'Date') {
            return this.renderDateComponent(ques);
        } else if (ques.questionType === 'Time') {
            return this.renderTimeComponent(ques);
        } else if (ques.questionType === 'ObjectiveType') {
            return this.renderChoicesComponent(ques);
        } else {
            return <span/>;
        }  
    }
    renderComponentOption = (ques) => {
        let index = ques.index;
        let position = ques.index+1;
        if (index.toString().indexOf("_") > -1) {
            let indexes = index.split("_");
            position = this.currentQuesNo + '.' + (parseInt(indexes[1])+1)
        }
        return <Popover
            id="popover-positioned-top"
            className="footer-popover position-option-component">
            <Popover.Title as='h2' className="popover-title">Position {position} <span className="closeMoveComponent" onClick={this.closeComponentOptions}><i className="fa fa-close"></i></span></Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button delete' onClick={() => this.deleteComponent(ques)} ><button >Delete</button></div> 
                    <div className='action-button'>
                        <span><OverlayTrigger 
                            show={this.state.quesRearrange.showPopoverPosition===ques.index}
                            trigger="click"
                            placement="left"
                            overlay={ this.renderComponentMove(ques) }
                        >
                            <span><button onClick={() => this.showMovePositionOverlay(ques)} >Move</button></span>
                        </OverlayTrigger></span> 
                    </div> 
                </div> 
            </PopoverContent>
        </Popover>; 
    }
    renderComponentMove = (ques) => {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Enter position to move <span className="closeMoveComponent" onClick={this.closeComponentOptions}><i className="fa fa-close"></i></span></Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <RRFInput
                        model={`.movePosition~${ques.index}`}
                        type="text"
                        placeholder=""
                        id="movePosition"
                        label={null} 
                        onChange={this.onFieldValueChangeForMovePosition}
                    /> 
                     <button type="button" className="move-position-button" onClick={this.submitMovePosition}>
                        Submit
                    </button>
                </div> 
            </PopoverContent>
        </Popover>;
    }
    onFieldValueChangeForMovePosition = (data) => { 
        let moveTo = data.target.value;
        let name = data.target.name.split('.');
        let dragIndex = name && name[2].split('~')[1]; 
        let insQues = [...this.state.insQues]; 
        if(!isNumeric(moveTo) || !isNumeric(dragIndex) || moveTo<1 || moveTo>insQues.length ) {
            toast.error('Invalid data.', { position: toast.POSITION.TOP_RIGHT, className: 'failed-toast' }); 
            return false;
        }
        moveTo = data.target.value - 1;
        if(data) {  
            if(name[2]) { 
                let quesRearrange = {...this.state.quesRearrange};
                /* rearrange  = {dragStart: dragIndex, dragTo: moveTo} */
                quesRearrange['dragStart'] = dragIndex;
                quesRearrange['dragTo'] = moveTo;
                this.setState({quesRearrange});  
            }
        }
        return true;
    }
    submitMovePosition = () => {
        let rearrange = this.state.quesRearrange; 
        this.handleDrop('', (rearrange.dragTo) ) 
        this.closeComponentOptions();
    }
    closeComponentOptions = ()=> {
        let quesRearrange = {...this.state.quesRearrange};
        quesRearrange['dragStart'] = '';
        quesRearrange['dragTo'] = '';
        quesRearrange['showPopoverPosition'] = '';
        quesRearrange['showPopoverOptions'] = '';
        this.setState({quesRearrange});
    }
    deleteComponent = async (ques) => { 
        let { insQues } = this.state;   
        let index = ques['index'];
        if (index.toString().indexOf("_") > -1) {
            let indexWithParentIndex = index.split("_");
            insQues[indexWithParentIndex[0]].subQuestions.splice(indexWithParentIndex[1],1)
        } else {
            insQues.splice(index, 1); 
        }
        if(ques && ques.questionId!=='') {
            let deletedComponents = [...this.state.deletedComponents]; 
            deletedComponents.push(ques.questionId);
            this.setState({deletedComponents}); 
        }
        this.reArrangeComponent(insQues); 
        this.closeComponentOptions();
    }
    reArrangeComponent = async (insQues) => {
        let newInsQues: any = []; 
        insQues.forEach((el,index) => { 
            let currQues: any = el; 
            currQues['index'] = index;
            currQues['priority'] = index + 1; 
            if(insQues.subQuestions && insQues.subQuestions.length>0) {
                insQues.subQuestions.forEach((el,subindex) => {
                    let currSubQues: any = el; 
                    currSubQues['index'] = subindex;
                    currSubQues['priority'] = subindex + 1; 
                    currQues.subQuestions.push(currSubQues);  
                }); 
            } 
            newInsQues.push(currQues);  
        }); 
        await this.setState({insQues: newInsQues}); 
    }

/*     
    handleChangeChoices = async (Choices, ques) => { 
        const insQues = [...this.state.insQues];
        let index = ques['index'];
        if (index.toString().indexOf("_") > -1) {
            let indexWithParentIndex = index.split("_");
            insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].choices = Choices; 
        } else {
            insQues[index].choices = Choices; 
        } 

        let flagged = insQues[ques['index']].isFlagged || false;
        if(flagged) {
            if(!Choices.includes(insQues[ques['index']].followUpOn) ) {
                insQues[ques['index']].followUpOn = '';
                insQues[ques['index']].isFlagged = false;
            }
        }
        await this.setState({insQues}); 
    }
 */

    handleChangeChoices = async (Choices, ques) => {

        const insQues = [...this.state.insQues];

        let flagged = false;

        let index = ques['index'];

        if (index.toString().indexOf("_") > -1) {

            let indexWithParentIndex = index.split("_");

            insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].choices = Choices;

            flagged = insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].isFlagged;

            if(flagged) {

                if(!Choices.includes(insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].followUpOn) ) {

                    insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].followUpOn = '';

                    insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].isFlagged = false;

                }

            }

        } else {

            insQues[index].choices = Choices;

            flagged = insQues[ques['index']].isFlagged;

            if(flagged) {

                if(!Choices.includes(insQues[ques['index']].followUpOn) ) {

                    insQues[ques['index']].followUpOn = '';

                    insQues[ques['index']].isFlagged = false;

                }

            }

        }  

        /* if(flagged) {

            if(!Choices.includes(insQues[ques['index']].followUpOn) ) {

                insQues[ques['index']].followUpOn = '';

                insQues[ques['index']].isFlagged = false;

            }

        } */

        await this.setState({insQues});

    }
    onFieldValueChange = async (data) => {
        if(data) {
            let name = data.target.name.split('.');
            if(name[2]) {
                const insQues = [...this.state.insQues];
                //insQues[name[2]].question = data.target.value; 
                let index = name[2];
                if (index.toString().indexOf("_") > -1) {
                    let indexWithParentIndex = index.split("_");
                    insQues[indexWithParentIndex[0]].subQuestions[indexWithParentIndex[1]].question = data.target.value; 
                    /* insQues[indexWithParentIndex[0]].subQuestions.splice(indexWithParentIndex[1],1) */
                } else {
                    insQues[index].question = data.target.value; 
                }
                await this.setState({insQues}); 
            }
        } 
    }
    onFieldHintValueChange = async (data, index) => {
        if(data) {
            // let name = data.target.name.split('.');
            if(index) {
                const insQues = [...this.state.insQues];
                //insQues[name[2]].question = data.target.value; 
                // let index = name[2];
                if (index.toString().indexOf("_") > -1) {
                    // let indexWithParentIndex = index.split("_");
                    insQues['forms'].subQuestions["plantForm"].question = data; 
                    /* insQues[indexWithParentIndex[0]].subQuestions.splice(indexWithParentIndex[1],1) */
                } else {
                    insQues[index].question = data; 
                }
                await this.setState({insQues}); 
            }
        }
    }
    onFlagValueChange = async (data, id) => { 
        const insQues = [...this.state.insQues];
        insQues[id].followUpOn = data.value==='No follow-up'?'':data.value; 
        insQues[id].isFlagged = data.value==='No follow-up'?false:true; 
        await this.setState({insQues}); 
    }
    showMovePositionOverlay = async (ques) => { 
        const quesRearrange = {...this.state.quesRearrange};
        quesRearrange['showPopoverPosition'] =  ques.index;
        await this.setState({quesRearrange}); 
        return;
    }
    showComponentOptionsOverlay = async (ques)=> { 
        const quesRearrange = {...this.state.quesRearrange};
        quesRearrange['showPopoverOptions'] = ques.index;
        await this.setState({quesRearrange}); 
        return;

    }
    renderChoicesComponent(ques) {  
        let choices = ques.choices || []; 
        let retChoices: Array<any> = [];
        retChoices.push({label: 'No follow-up', value: 'No follow-up'});
        choices.forEach(element => {
            retChoices.push({label: element, value: element});
        });
        return <div className="choice-question-container">
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Choice Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`}</div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="textarea"
                        placeholder="Enter choice question"
                        id="plantId"
                        label={null} 
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    />
                    <div className="choice-answer-container">
                        <div className="question">Choices</div>
                        <RRFInput
                            model=".plantId"
                            type="tagInput"
                            placeholder={choices.length < 6 ? 'Add an answer' : ''}
                            id="plantId"
                            label={null} 
                            defaultValue={choices}
                            additionalParams={ques}
                            onSelect={this.handleChangeChoices} 
                        />
                    </div>
                </Col>
                <Col xs={1} className="component-delete"  >
                    <span ><OverlayTrigger  
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }  
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                       <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span>  
                </Col> 
            </Row>
            <Row className='follow-up-container'>
                <Col xs={10} className="content">
                    <div className="question">Select follow-up answer:</div>
                    <RRFInput
                        model={`.${ques.index}Flag`}
                        type="dropdown"
                        placeholder="Select follow-up answer"
                        id={`${ques.index}`}
                        onSelect={this.onFlagValueChange}
                        label={null}  
                        defaultValue={(ques && (ques.followUpOn && ques.followUpOn===''?'No follow-up':ques.followUpOn) ) || 'No follow-up'} 
                        menuItems={retChoices}
                    />
                </Col>
            </Row>  
            {choices && choices.length>0 && ques.index && ques.index.toString().indexOf("_") < 0 &&<Row className='sub-question-button-container'>
                <div>
                    <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="right"
                            overlay={ this.createSubQuestionTo(ques) }
                        >
                        <button type="button" className="sub-question-button">
                        Create Sub-question
                    </button>
                    </OverlayTrigger> 
                </div>
            </Row>}
            { ques.subQuestions && ques.subQuestions.length>0 && 
                <div className="sub-questions-container">
                    { this.renderSubQuestions(ques) }
                </div>
            }
        </div>
    } 
    renderSubQuestions(ques) {
        //if(ques.subQuestions && ques.subQuestions.length>0) {
            return ques.subQuestions.map((subques, index) => {
                let subQuesIndex = `${ques.index}.${subques.index}`;
                let subQuesIndexForForm = `${ques.index}_${index}`;  
                /* return this.renderComponentFormat(subques, subQuesIndexForForm); */
                return <div draggable onDragStart={(e) => this.handleDragStart(e, subQuesIndexForForm)}
                onDragEnter={(e) => this.handleDragEnter(e, subQuesIndexForForm)}
                onDragLeave={(e) => this.handleDragLeave(e, subQuesIndexForForm)}
                onDrop={(e) => this.handleDrop(e, subQuesIndexForForm, )}
                onDragOver={(e) => e.preventDefault()} ><div className="drag-icon"><i className="fa fa-bars"></i></div> { this.renderComponentFormat(subques, subQuesIndexForForm) } </div>;  
            }); 
        //}
    }
    createSubQuestionTo(ques) {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Sub Question For</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body"> 
                { 
                    (ques.choices || []).map( (item) => {
                        return <div className='action-button'>
                         <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="right"
                            overlay={ this.createSubQuestionComponent(item, ques) }
                        >
                            <span><button >{item}</button></span>
                        </OverlayTrigger> 
                    </div> 
                    }) 
                } 
                </div> 
            </PopoverContent>
        </Popover>;
    }
    createSubQuestionComponent(to, ques) {
        return <Popover
            id="popover-positioned-top"
            className="footer-popover">
            <Popover.Title as='h2' className="popover-title">Question Type</Popover.Title>
            <PopoverContent className="new-component-popover-content">
                <div className="new-component-body">
                    <div className='action-button' onClick={() => this.addNewComponent('Text', to, ques)} ><button >Text</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Comments', to, ques)} ><button >Textarea</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Date', to, ques)} ><button >Date</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('Time', to, ques)} ><button >Time</button></div>
                    <div className='action-button' onClick={() => this.addNewComponent('ObjectiveType', to, ques)} ><button >Choices</button></div>
                </div> 
            </PopoverContent>
        </Popover>; 
    }
    processQuestionLabel = (ques) => {
        let quesNoLabel: any = this.currentQuesNo;
        let questionNum = ques && ques.index;
        if(questionNum && questionNum.toString().indexOf("_") > -1) {
            /* quesNoLabel =  questionNum.replace(/_/g, "."); */
            let split = questionNum.split('_');
            quesNoLabel = `${this.currentQuesNo}.${parseInt(split[1])+1}`;
        } else {  
            quesNoLabel = ++this.currentQuesNo
        }
        return quesNoLabel;
    }
    renderDateComponent(ques) { 
        return <div className="date-question-container">
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Date Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter date question"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    />
                </Col>
                <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> 
            </Row>
        </div>
    } 
    renderTimeComponent(ques) { 
        return <div className="time-question-container"> 
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Time Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter time question"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    />
                </Col>
                <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> 
            </Row>
        </div>
    } 
    renderTextAreaComponent(ques) { 
        return <div className="textarea-question-container"> 
            <Row>
                <Col xs={11} className="content">
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Textarea Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="textarea"
                        placeholder="Enter textarea question"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    />
                </Col>
                <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> 
            </Row>
        </div>
    } 
    renderHintTextAreaComponent(ques) { 

        return <div className='secondary-heading-container'>
        <Row>
            <Col xs={11} className="content">
                <div className="secondary-heading">Notes</div>
                {/* <CKEditor
                    editor={ ClassicEditor } 
                    data={ques.question} 
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.onFieldHintValueChange(data, ques.index) 
                    } } 
                /> */}
            </Col>
            <Col xs={1} className="component-delete">
                {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                <span><OverlayTrigger
                        /* rootClose */
                        trigger="click"
                        placement="right"
                        overlay={ this.renderComponentOption(ques) }
                        show={this.state.quesRearrange.showPopoverOptions===ques.index}
                    >
                    <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                </OverlayTrigger></span> 
            </Col> 
        </Row>
        </div>
    } 
    
    renderTextComponent(ques) { 
        return <div className="text-question-container">
            <Row> 
                <Col xs={11} className="content"> 
                    <div className="question"> <span className="question-no">{this.processQuestionLabel(ques)}</span>Text Question {`${ques && ques.answerSelected?`(Sub-Question for ${ques.answerSelected})`:''}`} </div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter text question"
                        id="plantId"
                        label={null}
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    /> 
                </Col>
                <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                </Col> 
            </Row>
        </div>
    } 
    renderSubTitleComponent(ques) {
        return <div className='secondary-heading-container'>
            <Row>
                <Col xs={11} className="content">
                    <div className="secondary-heading">Sub Title</div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter sub-title"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    />
                </Col>
                <Col xs={1} className="component-delete">
                    {/* <span onClick={ () => this.deleteComponent(ques)}><i className="fa fa-trash" aria-hidden="true"></i></span> */}
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                        <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                </Col> 
            </Row>
        </div>
    } 
    renderTitleComponent(ques) { 
        return <div className="field-heading-container">
            <Row>
                <Col xs={11} className="content">
                    <div className="field-heading">Title</div>
                    <RRFInput
                        model={`.${ques.index}`}
                        type="text"
                        placeholder="Enter title"
                        id="plantId"
                        label={null}  
                        defaultValue={ques && ques.question}
                        onChange={this.onFieldValueChange}
                    />
                </Col>
                <Col xs={1} className="component-delete">
                    <span><OverlayTrigger
                            /* rootClose */
                            trigger="click"
                            placement="right"
                            overlay={ this.renderComponentOption(ques) }
                            show={this.state.quesRearrange.showPopoverOptions===ques.index}
                        >
                       <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={()=>this.showComponentOptionsOverlay(ques)}></i>
                    </OverlayTrigger></span> 
                </Col>
            </Row>
        </div>
    } 
    renderContent() {
        return (
            <>
                {<Dialog  ref={(el: any) => {
                        this.dialog = el;
                    }}/>}
                {this.state.isLoading ? <Loader type="submission"/> : ''}
                <Row className='inspection-setup-questions'> 
                    <Form
                        model="forms.plantForm"
                        onSubmit={this.onSubmitForm}>
                        <div className="inspection-setup-questions-body">
                            {
                                this.renderComponents()
                            }
                        </div>
                        <div className="create-new-component-container"> 
                            <span><OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="right"
                                    overlay={ this.createNewComponent() }
                                >
                                <button type="button" className="create-new-component">
                                
                                    <span>Create new component</span> 
                                </button>
                            </OverlayTrigger></span>  
                        </div> 
                    </Form>
                </Row>
                <Row className="form-footer">
                    <button type="button" onClick={this.handleCancel} className="cancel-button">
                        Cancel
                    </button>
                    <button type="button" onClick={this.onSubmitForm} className="submit-button">
                        Submit
                    </button>
                </Row>
            </>
        )
    }
    handleFormRename = () => {
        const { formId, formName} = this.props;
        this.setState({
            displayView: true, inspectionName: formName 
        }) 
    }
    renderPrintButton = () => {
        return<div>
         {this.props.userAccess_Level == 'L1' ?     
            <div style={{
            position: 'absolute',
            right: '75px',
        }} className="button d-print-none faiconSize" onClick={this.handleFormRename} id="print-button">
            <i className="fa fa-pencil" aria-hidden="true"/>
            &nbsp; 
        </div> :
        <div style={{
            position: 'absolute',
            right: '25px',
        }} className="button d-print-none faiconSize" onClick={this.handleFormRename} id="print-button">
            <i className="fa fa-pencil" aria-hidden="true"/>
            &nbsp; 
        </div> }
        {this.props.userAccess_Level == 'L1' ? 
        <div style={{
            position: 'absolute',
            right: '25px',
        }} className="button d-print-none faiconSize" onClick={this.handleInspectionDelete} id="print-button">
            <i className="fa fa-trash" aria-hidden="true"/>
            &nbsp; 
        </div>
        : ''}
        </div>;
    }
    handleInspectionDelete = () => { 
        this.dialog.show({
            body: `Are you sure about deleting ${this.props.formName} form ?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => deleteInspectionForms(this.props.formId, this.props.history))
            ]
        });
    }
    hidePreviewModal = (fname) => {
        this.setState({
            displayView: false 
        })
        hidePrintPreviewModal();
    }
    onInspectionNameChange = (data) => {
        this.setState({
            inspectionName: data.target.value
        })
    }
    handleUpdateInsptName = async () => {
        const { formId, formName, permitType} = this.props;
        await this.setState({isLoading: true})
        let res = await updateInspectionFormName(this.state.inspectionName.trim(), this.props.formId, this.props.history)
        if(res){ 
            this.setState({displayView: false, isLoading: false});
            this.props.history?.replace(`/inspection-setup/${formId}/${permitType}/${this.state.inspectionName}`);
        }
        else 
            this.setState({isLoading: false})  
    }
    render() {
        const { formId, formName} = this.props;
        const { inspectionName } = this.state;
        return (
            <BasePage pageTitle={`Inspection Set-up - ${inspectionName || formName}`} sideButton={this.renderPrintButton()}>
                <div className="plant-detail-container inspection-setup-container">
                {this.state.displayView === true ?
                        <Modal size="lg" className="print-preview-outer" show={true} onHide={() => this.setState({ displayView: false, inspectionName: '' })}> 
                        {this.state.isLoading ? <Loader type="submission"/> : ''}
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: 'white' }}>Update Inspection. </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ color: 'black', backgroundColor: 'white' }}> 
                            <RRFInput
                                model=".Inspection_Name"
                                type="text"
                                placeholder="Enter Inspection name..."
                                id="Inspection_Name"
                                label="Inspection Name :"
                                onChange={this.onInspectionNameChange} 
                                defaultValue={formName}
                            />
                            <Row className="delete-form-footer">
                                <button type="button" onClick={() => this.setState({ displayView: false, inspectionName: '' })} className="cancel-button">
                                    Cancel
                                </button>
                                <button type="button" onClick={() => this.handleUpdateInsptName()} className="submit-button">
                                    Update 
                                </button>
                            </Row>
                        </Modal.Body>
                    </Modal > : ''}
                    <Async
                        identifier="QuestionSetup"
                        promise={this.promise}
                        loader={<Loader/>}
                        error={<ErrorPage/>}
                        content={this.renderContent()}
                    />
                </div>
            </BasePage>
        );
    } 
}

function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    const userAccess_Level = parseJwt(state.login.get('token')).Access_Level;
    const formId = ownProps.match.params.formId;
    const formName = ownProps.match.params.formName;
    const permitType = ownProps.match.params.permitType;
    return { userUID: state.login.get('UniqueId'), userName, formId, formName, permitType, userAccess_Level, };
}

export const QuestionSetupForm = withRouter(connect< IQuestionSetupProps, any, any>(mapStateToProps)(QuestionSetupImpl as any));
