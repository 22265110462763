import * as React from 'react';
import {inspectionType} from '../../constants/generals';

export interface IFlaggedFormListItemProps {
    index: number;
    inspectionData: any;
    onClick(UniqueId: string, type: string): void;
}

export class FlaggedFormListItem extends React.PureComponent<IFlaggedFormListItemProps, {}> {

    static defaultProps: IFlaggedFormListItemProps;

    render() {
        const {PlantId, RegionName, FacilityType, PermitType, Date, Completed} = this.props.inspectionData;
        const type = 'Inspection Form';
        return < div className="modal-list-item">
            <div className="plant-id plantId" onClick={() => this.props.onClick(PlantId, type)}
                 style={{width: `${20}%`}}>{PlantId}</div>
            <div className="plant-id" onClick={() => this.props.onClick(PlantId, type)}
                 style={{width: `${20}%`}}>{RegionName}</div>
            <div className="plant-id" onClick={() => this.props.onClick(PlantId, type)}
                 style={{width: `${20}%`}}>{FacilityType}</div>
            <div className="plant-id" onClick={() => this.props.onClick(PlantId, type)}
                 style={{width: `${20}%`}}>{PermitType}</div>
            <div style={{width: `${20}%`, paddingLeft: '4%'}} onClick={() => this.props.onClick(PlantId, type)} className={(Completed !== 1  ? 'pending' : 'complete') + ` clickable-list-item flag-status`}>
                {Completed !== 1 ?
                    [<i key={0} className="fa fa-clock-o"/>, ` Pending`] :
                    [<i key={1} className="fa fa-check-circle-o"/>, ` Complete`]
                }
            </div>
        </div>;
    }
}

const buttonStyle: React.CSSProperties = {
    width: '67.48px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center'
};
