import './reportsPage.scss';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as _ from 'lodash';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {IHistory} from '../../../interfaces';
import {getDailyChecklistData, getFormSummaryQuestions, saveFlagCompletions} from '../../../services/dailyFormService';
import {Async} from '../../reusableComponents/Async';
import {BasePage} from '../../reusableComponents/BasePage';
import {ErrorPage} from '../../reusableComponents/ErrorPage';
import {Loader} from '../../reusableComponents/Loader';
import {MonthPicker} from '../../reusableComponents/MonthPicker';
import {HOUSEKEEPINGSCORES, monthsAndYears, years} from '../../../constants/generals';
import {prepareTable} from '../../../services/formService';
import {SelectDropdown} from '../../reusableComponents/SelectDropdown';
import {format} from 'date-fns';
import { getAnswerWithFlag, getFirstLetters } from '../../../utils/generalUtils';
import { MarkAsCompletedPopOver } from '../../reusableComponents/MarkAsCompletePopOver';
import moment from 'moment';


export interface IDailyChecklistReportProps {
    history?: IHistory;
    frequency: string;
}

export interface IDailyChecklistReportState {
    questionsList: any;
    answersList: any;
    date: any;
    omittedQues: any;
    deletedQues: any; 
}

export class DailyChecklistReportImpl extends React.Component<IDailyChecklistReportProps, IDailyChecklistReportState> {
    basicParams = JSON.parse(sessionStorage.getItem('inspectionDetails') || '{}');
    questionKeys: Array<any> = [];
    skipAnswers: Array<any> = [];
    pageTitle: string | null = null;
    outfall = this.basicParams.PointSources && this.basicParams.PointSources.length && this.basicParams.selectedPS.PsName && this.basicParams.selectedPS.PsName !== 'Select' ? this.basicParams.selectedPS.PsName : null;
    monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    yearNames = ['2022', '2023', '2024', '2025', '2026', '2027']
    constructor(props: IDailyChecklistReportProps) {
        super(props);
        this.state = {questionsList: [], answersList: [], omittedQues: [], deletedQues: [], date: this.basicParams.date};
    }

    promise = async () => {
        const {frequency, plantInfo, FormId} = this.basicParams;
        this.addEventListener() 
        const dateToGet = this.extractXHRMonthAndYear(this.state.date);
        const dateToSend = frequency === 'Daily' || frequency === 'Weekly' ? dateToGet.month + dateToGet.year : dateToGet.year;
        const questionsList = await getFormSummaryQuestions(this.basicParams.FormId);
        //const answersList = await (frequency, plantInfo.id, dateToSend, FormId, this.basicParams.selectedPS.PsId);
        const [answersList, omittedQues, deletedQues] = await getDailyChecklistData(frequency, plantInfo.id, dateToSend, FormId, this.basicParams.selectedPS.PsId);
        questionsList.push({
            question: 'Submitted On:',
            questionId: 'formSubmittedDate',
            questionType: 'Date'
        });
        this.setState({ questionsList, answersList, omittedQues, deletedQues });
        
    }
    addEventListener () {
        document.getElementById("summaryContainer")?.addEventListener("scroll", myFunction); 
        var thiz = this;
        function myFunction() {  
            /* var elmnt = document.getElementById("dates");
            var elmnt2 = document.getElementById("summaryContainer");
            if(elmnt && elmnt2) { 
                elmnt2.scrollLeft = elmnt.scrollLeft; 
            } */
            thiz.scrollYAction()
        }
        window.addEventListener('resize', () => {
            /* console.log(`Actual dimensions: ${window.innerWidth}x${window.innerHeight}`);  */
        });
    }
    extractXHRMonthAndYear = (date) => {
        let [month, day, year] = date.split('-');
        monthsAndYears.map((data) => {
            if (data.label === day) {
                day = `${data.value}${this.basicParams.date.substring(this.basicParams.date.length - 4)}`;
            }
            if (data.label === month) {
                month = `${data.value}${this.basicParams.date.substring(this.basicParams.date.length - 4)}`;
            }
            if (data.label === year) {
                year = `${data.value}${this.basicParams.date.substring(this.basicParams.date.length - 4)}`;
            }
            return true;
        });
        return {day, month, year};
    }

    filterList = async (date) => {
        const {frequency, plantInfo, FormId} = this.basicParams; 
        date = moment(date.replace(/-/g, '/')).format('MM-DD-yyyy') 
        const dateToGet = this.extractXHRMonthAndYear(date);
        //console.log(date)
        /* const dateToGet = this.extractXHRMonthAndYear(format(new Date(date.replace(/-/g, '/')), 'MM-dd-yyyy'));
        date = format(new Date(date.replace(/-/g, '/')), 'MM-dd-yyyy'); */
        const dateToSend = frequency === 'Daily' || frequency === 'Weekly' ? dateToGet.month + dateToGet.year  : dateToGet.year;
        
        setLoading('DailyChecklistData');
        const [answersList, omittedQues, deletedQues] = await getDailyChecklistData(frequency, plantInfo.id, dateToSend, FormId, this.basicParams.selectedPS.PsId);
        this.setState({ date, answersList, omittedQues, deletedQues });
        setSuccess('DailyChecklistData');
    }

    renderDates = () => {
        const answersList = this.state.answersList;
        // if (this.basicParams.FormId === '8364283d-7870-48e1-a01a-5736f9c2afdb' || this.basicParams.FormId === '83281b89-15ed-4f89-871b-82260e4b00b0') {
        if (this.basicParams.ParentId === 'Maintenace Record263' || this.basicParams.ParentId === 'Improvements & CAPEX708') {
        return <div className="dates" id="date-container">
            {answersList.map((ans, index) => {
                return <h4 key={index + 999} style={{width: `${100 / answersList.length}%`}}>
                    {this.basicParams.frequency === 'Yearly' ? ans.Year ? ans.Year : this.yearNames[index] : this.basicParams.frequency === 'Weekly' ? ans['WeekDate'] :(this.basicParams.frequency === 'Monthly' ? this.monthNames[index] :(this.basicParams.frequency === 'Quarterly' ? 'Q'+(index + 1) : index + 1))}
                </h4>;
            })}
        </div>;
        } else {
            return <div className="dates" id="date-container">
                {answersList.map((ans, index) => {
                    return <h4 key={index + 999} style={{width: `${100 / answersList.length}%`}}>
                        {this.basicParams.frequency === 'Yearly' ? (ans.Year ? ans.Year : this.yearNames[index]) : this.basicParams.frequency === 'Weekly' ? ans['WeekDate'] :(this.basicParams.frequency === 'Monthly' ? this.monthNames[index] :(this.basicParams.frequency === 'Quarterly' ? 'Q'+(index + 1) : index + 1))}
                    </h4>;
                })}
            </div>;
        }
    }

    renderQuestions = () => {
        const questions: Array<any> = this.state.questionsList;
        const omittedQues: any = this.state.omittedQues; 
        this.questionKeys = [];
        return <div className="questions">
            {questions.map((que, index) => {
                if(que.questionType === 'Paragraph' || (omittedQues.length>0 && omittedQues.includes(que.questionId) ) ) {
                    this.skipAnswers.push(que.questionId)
                } else if (que.questionType !== 'Hint') {
                    const questionClass = que.questionType === 'Title' ? 'title' : que.questionType === 'Subtitle' ? 'sub-title' : '';
                    this.questionKeys.push({[que.questionId]: null});
                    //return <h4 className={questionClass} key={index + 88} title={que.question}>{que.question}</h4>;
                    let finalOp = <h4 className={questionClass} key={index + 88} >{que.question}</h4>
                    return <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={ <Tooltip id={`TooltipQuesHint-${index}`}> <span>{que.question}</span> </Tooltip>}>
                                { <span className='tooltip-text' > {finalOp}</span> }
                            </OverlayTrigger> 
                }
            })}
        </div>;
    } 
    
    renderAnswers = () => {
        const questions: Array<any> = this.state.questionsList;
        const answers = this.state.answersList.length ? this.state.answersList : [];
        const Tick = <i className="fa fa-check" aria-hidden="true"/>;
        const Cross = <i className="fa fa-close"/>; 
        let leadQuestionText = '';
        switch(this.basicParams.ParentId) {
            case 'Housekeeping Plant80':
                leadQuestionText = 'Plant Leadperson'; break;
            case 'Housekeeping Shop80':
                leadQuestionText = 'Shop Leadperson'; break;
            case 'Daily-Weekly Check (All)80': case 'Daily-Weekly Check (additional)80': case 'Yearly Check80': case 'Monthly Check (All)80':
                leadQuestionText = 'Plant Manager sign off'; break; 
        }
        const quesIndex = _.findIndex(questions, {question: leadQuestionText});
        let leadQues = questions[quesIndex];  

        return <div className="answers">
            {answers.map((data, index) => {
                if (!data.questionAndAnswer || data.questionAndAnswer && data.questionAndAnswer.length === 0) {
                    data.questionAndAnswer = this.questionKeys;
                } 
                const submittedDateObj = _.find(data.questionAndAnswer, {formSubmittedDate: null });
                if (submittedDateObj && !submittedDateObj.formSubmittedDate) { 
                    submittedDateObj.formSubmittedDate = data.Date && data.Date!== 'None' ? format(new Date(data.Date.replace(/-/g, '/')), 'MM-dd-yyyy') : null;
                } else {  
                    data.questionAndAnswer['formSubmittedDate'] = {answer: data.Date && data.Date!== 'None' ? format(new Date(data.Date.replace(/-/g, '/')), 'MM-dd-yyyy') : null}
                } 
                return <div
                    key={index + 99}
                    style={{display: 'inline-block', width: `${100 / answers.length}%`}}
                    className="answer-row">
                    {this.questionKeys.map((ques, index) => {   
                        let key = Object.keys(ques)[0] 
                        let answerData = data.questionAndAnswer[key]; 
                        const quesNo = (answerData && Object.keys(answerData)[0]) || '';

                        if(this.skipAnswers.indexOf(quesNo) <0 ) { 
                            let answer: any = null;
                            let isFlagged = false; 
                            if( answerData  ) {
                                answer = answerData['answer'];
                                isFlagged = answerData['isFlagged'];
                            }   
                            let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(answerData) 
                            let answerToShow: any = ''; 
                            const directAnswer = <div className={'other-answer'} title={answer || ''}>{answer}</div>;
                            switch(this.basicParams.ParentId) {
                                case 'Housekeeping Plant80': case 'Housekeeping Shop80': 
                                    if(leadQues.questionId === key) {
                                        answerToShow = <>
                                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ <Tooltip id={`TooltipQuestionHint-${key}`}> <span>{answer}</span> </Tooltip>}>
                                            { <span className='tooltip-text' > {getFirstLetters(answer).toUpperCase()}</span> }
                                        </OverlayTrigger>  
                                    </>
                                    } else { 
                                        answerToShow = HOUSEKEEPINGSCORES.includes(answer) ? <div title={answer}>{HOUSEKEEPINGSCORES.indexOf(answer) + 1 }</div>: directAnswer; 
                                    }
                                    break;
                                case 'Daily-Weekly Check (All)80': case 'Daily-Weekly Check (additional)80':
                                    if(answer) {
                                        let answers = answer.split('~');
                                        if(answers.length>0) { 
                                            let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                                            if(answers.length<2) {
                                                answerToShow = (answers[0]!==''? answers[0] :'')
                                            } else {
                                                answers.map( (item, index) => {  
                                                    answerToShow += (item!=='' ?  (days[index] + '-' + item + ', '):''
                                                )
                                                });
                                            } 
                                        } 
                                    }
                                    break;
                                case 'Yearly Check80':
                                    if(answer) {
                                        let answers = answer.split('~');
                                        if(answers.length>0) { 
                                            let days = ['6 Month', '12 Month']
                                            if(answers.length<2) {
                                                answerToShow = (answers[0]!==''? answers[0] :'')
                                            } else {
                                                answers.map( (item, index) => { 
                                                    answerToShow += item!=='' ?(days.includes(item) ? (days[index]+ ', '):(days[index] + ' - '+ item + ', ') ):'' ; 
                                                });
                                            } 
                                        } 
                                    }
                                    break;
                                default: 
                                    let acualAnswer = data.postiveAnswer.indexOf(answer) > -1 ? Tick : data.negativeAnswer.indexOf(answer) > -1 ? Cross : directAnswer;

                                    /* answerToShow = answer === null ? <span/> : <>
                                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ <Tooltip id={`TooltipAnswerHint-${index}`}> <span>{acualAnswer}</span> </Tooltip>}>
                                            { <span className='tooltip-text' > {acualAnswer}</span> }
                                        </OverlayTrigger>  
                                    </> */
                                    answerToShow = answer === null ? <span/> : data.postiveAnswer.indexOf(answer) > -1 ? Tick : data.negativeAnswer.indexOf(answer) > -1 ? Cross : directAnswer; 
                                    break;
                            }  
                            /* let finalOp = <h4 key={index} className="answer-column"> {isFlagged ? <div className="flagged-answer"><MarkAsCompletedPopOver ref="popOver" formSubmittedDate={data.Date} questionId={key} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue} inspectionId={data.inspectionUId}>
                                <div className={`${followUpClass} mark-as-complete-col`} title="Click to enter the follow-up comment">
                                    <i className={followUpLogo} aria-hidden="true"> </i> {answerToShow}
                                </div> 
                                </MarkAsCompletedPopOver></div> : answerToShow } 
                            </h4>
                            return <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ <Tooltip id={`TooltipAnswerHint-${index}`}> <span>{finalOp}</span> </Tooltip>}>
                                { <span className='tooltip-text' > {finalOp}</span> }
                            </OverlayTrigger>   */
                            return <h4 key={index} className="answer-column"> {isFlagged ? <div className="flagged-answer"><MarkAsCompletedPopOver ref="popOver" formSubmittedDate={data.Date} questionId={key} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue} inspectionId={data.inspectionUId}>
                                <div className={`${followUpClass} mark-as-complete-col`} title="Click to enter the follow-up comment">
                                    <i className={followUpLogo} aria-hidden="true"> </i> {answerToShow}
                                </div> 
                            </MarkAsCompletedPopOver></div> : answerToShow } </h4>
                        } else { //Skip Answer
                            return <h4 key={index} className="answer-column"></h4>
                        }
                    })}
                </div>;
            })}
        </div>;
    }
    renderAnswers__OLD = () => {
        const answers = this.state.answersList.length ? this.state.answersList : [];
        const Tick = <i className="fa fa-check" aria-hidden="true"/>;
        const Cross = <i className="fa fa-close"/>; 
        return <div className="answers">
            {answers.map((data, index) => {
                if (!data.questionAndAnswer || data.questionAndAnswer && data.questionAndAnswer.length === 0) {
                    data.questionAndAnswer = this.questionKeys;
                } 
                const submittedDateObj = _.find(data.questionAndAnswer, {formSubmittedDate: null });
                if (submittedDateObj && !submittedDateObj.formSubmittedDate) { 
                    submittedDateObj.formSubmittedDate = data.Date ? format(new Date(data.Date.replace(/-/g, '/')), 'MM-dd-yyyy') : null;
                } else { 
                    //data.questionAndAnswer.push({'formSubmittedDate': {answer: data.Date ? format(new Date(data.Date.replace(/-/g, '/')), 'MM-dd-yyyy') : null} });
                    data.questionAndAnswer['formSubmittedDate'] = {answer: data.Date ? format(new Date(data.Date.replace(/-/g, '/')), 'MM-dd-yyyy') : null}
                }  
                let submittedDate =  data.Date
                return <div
                    key={index + 99}
                    style={{display: 'inline-block', width: `${100 / answers.length}%`}}
                    className="answer-row">
                    {this.questionKeys.map((ques, index) => {   
                        let key = Object.keys(ques)[0] 
                        let answerData = data.questionAndAnswer[key];
                        
                        /* let answerData = data.questionAndAnswer && data.questionAndAnswer[key]!==undefined?data.questionAndAnswer[key]:'' */
                    /* {data.questionAndAnswer.map((answerData, index) => {  */
                    {/* {data.questionAndAnswer.each((answerData, index) => {  */}

                        const quesNo = (answerData && Object.keys(answerData)[0]) || '';
                        if(this.skipAnswers.indexOf(quesNo) <0 /* && quesNo!=='' */) {  
                            let answer = null;
                            let isFlagged = false; 
                            if( answerData[quesNo] ) {
                                answer = answerData[quesNo]['answer'];
                                isFlagged = answerData[quesNo]['isFlagged'];
                            }  
                            
                            let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(answerData[quesNo]) 
                            const directAnswer = <div className={'other-answer'} title={answer || ''}>{answer}</div>;
                            let answerToShow = answer === null ? <span/> : data.postiveAnswer.indexOf(answer) > -1 ? Tick : data.negativeAnswer.indexOf(answer) > -1 ? Cross : directAnswer;
                           /*  return <h4
                                key={index}
                                className="answer-column">
                                {
                                    answer === null ? <span/> : data.postiveAnswer.indexOf(answer) > -1 ? Tick : data.negativeAnswer.indexOf(answer) > -1 ? Cross : directAnswer
                                }
                            </h4>; */
                            return <h4 key={index} className="answer-column"> {isFlagged ? <div className="flagged-answer"><MarkAsCompletedPopOver ref="popOver" formSubmittedDate={data.Date} questionId={quesNo} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue} inspectionId={data.inspectionUId}>
                            <div className={`${followUpClass} mark-as-complete-col`}
                                 title="Click to enter the follow-up comment">
                                <i className={followUpLogo} aria-hidden="true"> </i> {answerToShow}
                            </div> 
                        </MarkAsCompletedPopOver></div> : answerToShow } </h4>
                        } else {
                            return <h4 key={index} className="answer-column"></h4>
                        }
                    })}
                </div>;
            })}
        </div>;
    }
    
    handleFlagAnswerUpdate = async (comments, date, questionId, inspectionId) => {
        await saveFlagCompletions(inspectionId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'), this.props.history).then(async (res) => {
            setLoading('DailyChecklistData');
            await this.promise(); 
            setSuccess('DailyChecklistData');
        });
    }
    scrollXAction = () => {
        var elmnt = document.getElementById("date-container");
        var elmnt2 = document.getElementById("summary-container");
        if(elmnt && elmnt2) { 
            elmnt.scrollLeft = elmnt2.scrollLeft;  
        }
        let quesColumn = Array.from(document.getElementsByClassName('questions-column') as HTMLCollectionOf<HTMLElement>);
        quesColumn.forEach((element) => { 
            element.style.position = 'fixed';  
        }); 

        /* let datesRow = Array.from(document.getElementsByClassName('dates-row') as HTMLCollectionOf<HTMLElement>);
        datesRow.forEach((element) => { 
            element.style.width = '100%';  
            element.style.marginLeft = '0%'
        }); 

        let answerData = Array.from(document.getElementsByClassName('answers-data') as HTMLCollectionOf<HTMLElement>);
        answerData.forEach((element) => { 
            element.style.width = '100%';  
            element.style.left = '0%'
        }); 

        let questionColumn = Array.from(document.getElementsByClassName('questions-column') as HTMLCollectionOf<HTMLElement>);
        questionColumn.forEach((element) => { 
            element.style.width = '0%';   
        }); */ 
    }
    scrollYAction = () => { 
        let quesColumn = Array.from(document.getElementsByClassName('questions-column') as HTMLCollectionOf<HTMLElement>); 
        quesColumn.forEach((element) => { 
            element.style.position = 'absolute';  
        }); 
    }
    XReachStart = () => {
        /* let datesRow = Array.from(document.getElementsByClassName('dates-row') as HTMLCollectionOf<HTMLElement>);
        datesRow.forEach((element) => { 
            element.style.width = '87.1%';  
            element.style.marginLeft = '12.9%'
        }); 

        let answerData = Array.from(document.getElementsByClassName('answers-data') as HTMLCollectionOf<HTMLElement>);
        answerData.forEach((element) => { 
            element.style.width = '87.1%';  
            element.style.left = '12.9%'
        }); 

        let questionColumn = Array.from(document.getElementsByClassName('questions-column') as HTMLCollectionOf<HTMLElement>);
        questionColumn.forEach((element) => { 
            element.style.width = '12.98%';   
        }); */ 
        
    }
    renderContent = () => {
        const dummyMonth = this.state.date.substring(0, 2) + '-01-' + this.state.date.substring(this.state.date.length - 4);  
        return <div>
            <div className="chart-title" id="chartTitle">
                {(this.props.frequency === 'Daily' || this.props.frequency === 'Weekly' ? <div className={ 'select-dropdown'}> <MonthPicker
                    defaultValue={dummyMonth}
                    years={[2020, 2021, 2022]}
                    onClick={this.filterList}
                    popover={false}
                /></div> : <SelectDropdown
                    onChange={this.filterList}
                    menuItems={years()}
                    defaultValue={format(new Date(this.state.date.replace(/-/g, '/')), 'yyyy')}
                />)}
            </div>
            <Async
                identifier="DailyChecklistData"
                promise={() => setSuccess('DailyChecklistData')}
                loader={<Loader type="async"/>}
                content={
                    <div style={{display: 'flex'}} className={`${this.props.frequency}-Checklist-Container`}  >
                        <div className="dates-row">{this.renderDates()}</div>
                        <PerfectScrollbar onScrollX={this.scrollXAction} onScrollY={this.scrollYAction} /* onXReachStart={this.XReachStart} */ id="summary-container">
                            <div id="summaryContainer" style={{height: '84vh'}}>
                                <div className="questions-column">{this.renderQuestions()}</div>
                                <div className="answers-data">{this.renderAnswers()}</div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                }
                error={<ErrorPage/>}
            />
        </div>;
    }

    handlePrint = () => {
        const {frequency} = this.basicParams;
        const unit = frequency === 'Daily' ? 'Days' : frequency === 'Weekly' ? 'Weeks' : frequency === 'Monthly' ? 'Months' : frequency === 'Quarterly' ? 'Quarters' : 'Years';
        let colWidth = 150;  
        switch(frequency) {
            case 'Daily': colWidth = this.state.answersList?.length===31? 11: (this.state.answersList?.length===30?12:13); break;
            case 'Weekly': colWidth = 120; break;
            case 'Monthly': colWidth = 45; break;
            case 'Yearly': colWidth = this.state.answersList?.length>1?540/this.state.answersList.length:610; break;   
            default: colWidth = 150; break;
        }

        /* let colWidth = frequency === 'Daily' ? 13 : (frequency === 'Monthly' ? 45 : (frequency === 'Weekly' ? 120 :  150)) ; */
        prepareTable(this.state.answersList, this.state.questionsList, this.pageTitle, colWidth, unit);
    }

    render() {
        const date = this.props.frequency === 'Daily' ? monthsAndYears[new Date(this.state.date.replace(/-/g, '/')).getMonth()].label + ' ' + new Date(this.state.date.replace(/-/g, '/')).getFullYear() : format(new Date(this.state.date.replace(/-/g, '/')), 'yyyy');
        const {FormName, plantInfo, selectedPS} = this.basicParams;
        this.pageTitle = this.outfall ? `${FormName} (${selectedPS.PsName}) Reports for ${date} for Plant ${plantInfo.plantId}` : `${FormName} Reports for ${date} for Plant ${plantInfo.plantId}`;
        return <BasePage
            className="daily-checklist"
            pageTitle={this.pageTitle}
            sideButton={
                <div key="details" onClick={this.handlePrint} className="button" id="print-button" style={{
                    position: 'absolute',
                    right: '10px',
                    width: 'fit-content',
                    paddingRight: '10px'
                }}>
                    <i className="fa fa-print" aria-hidden="true"/>
                    &nbsp;
                    <span> Print</span>
                </div>
            }>
            <Async
                identifier="DailyChecklistReport"
                promise={this.promise}
                loader={<Loader type="async"/>}
                content={this.renderContent()}
                error={<ErrorPage/>}
            />
        </BasePage>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {
        frequency: ownProps.match.params.frequency
    };
}

export const DailyChecklistReport = withRouter(connect<IDailyChecklistReportProps, any, any>(mapStateToProps)(DailyChecklistReportImpl as any));
