import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {actions} from 'react-redux-form';
import {toast} from 'react-toastify';
import * as _ from 'lodash';
import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {UserModel} from '../../model/UserModel';

import {getAllPlantsByMultipleRegions} from '../../services/plantService';
import {dispatch, frameSelectOptions, isFormEmpty} from '../../utils/generalUtils';
import {Async} from '../reusableComponents/Async';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Form} from '../reusableComponents/FormComponents/Form';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import {Loader} from '../reusableComponents/Loader';
import {getAllOrgs, getRegionsByOrg} from '../../services/orgService';
import {parseJwt} from '../../services/loginService';
import {IHistory} from '../../interfaces';
import {withRouter} from 'react-router-dom';
import './user.scss';

export interface IUserFormProps {
    formModelName?: string;
    id?: string;
    userId : string;
    userInstance?: UserModel;
    loggedInUserInfo?: any;
    history?: IHistory;
    onSubmit?: (values) => void;
} 

export interface IUserFormState {
    userInfo?: any;
    isLoading: boolean;
    selectOptions: { userLevelOptions: Array<any>, orgOptions: Array<any>, regionOptions: Array<any>, plantOptions: Array<any> };
    fieldEnabler: { selectedLevel: string, enableOrg: boolean, enableRegion: boolean, enablePlants: boolean };
}

export class UserFormImpl extends React.Component<IUserFormProps, IUserFormState> {
    static defaultProps: IUserFormProps;

    constructor(props: IUserFormProps) {
        super(props);

        const loggedInLevel = props.loggedInUserInfo.Access_Level;

        const selectedLevel = loggedInLevel === 'L1' ? 'L2' : loggedInLevel === 'L2' ? 'L2' : 'L4';
        const userInfo = {
            'First_Name': props.userInstance ? props.userInstance.props.First_Name : null,
            'Last_Name': props.userInstance ? props.userInstance.props.Last_Name : null,
            'Middle_Name': props.userInstance ? props.userInstance.props.Middle_Name : null,
            'UserId': props.userInstance ? props.userInstance.props.UserId : null,
            'Plants': props.userInstance ? _.map(props.userInstance.props.Plants, 'plantId') : [],
            'Regions': props.userInstance ? _.map(props.userInstance.props.Regions, 'regionId') : [],
            'Access_Level': props.userInstance ? props.userInstance.props.Access_Level : selectedLevel,
            'orgId': props.userInstance ? props.userInstance.props.orgId : props.loggedInUserInfo.orgId,
            'Job_Desc': props.userInstance ? props.userInstance.props.Job_Desc : null,
            'Job_Title': props.userInstance ? props.userInstance.props.Job_Title : null,
            'UniqueId': props.userInstance ? props.userInstance.props.UniqueId : null,
            'Email': props.userInstance ? props.userInstance.props.Email : null
        };
        this.state = {
            userInfo,
            isLoading: false,
            selectOptions: {
                userLevelOptions: this.getUserLevelOptions(loggedInLevel),
                orgOptions: [],
                regionOptions: [],
                plantOptions: []
            },
            fieldEnabler: {selectedLevel, enableOrg: loggedInLevel === 'L1', enableRegion: false, enablePlants: false}
        };
    }

    promise = async () => {
        await this.getOrgsList();
        const {userInfo} = this.state;
        if (this.props.loggedInUserInfo.Access_Level === 'L3') {
            const selectOptions = this.state.selectOptions;
            selectOptions.regionOptions = frameSelectOptions(this.props.loggedInUserInfo.Regions, 'regionId', 'regionName');
            this.setState({
                selectOptions
            });
        } else if (userInfo.orgId) {
            this.getRegionsListByOrg(userInfo.orgId);
        }

        if (userInfo.Access_Level === 'L4' || userInfo.Access_Level === 'L3') {
            this.getPlantsListByRegion(userInfo['Regions']);
        }
    }

    handleFormSubmit = async () => {
        const value = this.state.userInfo;
        const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
        const notRequired = ['Middle_Name', 'UniqueId'];
        if (value && value.Access_Level === 'L1') {
            notRequired.push('orgId', 'Regions', 'Plants');
        } else if (value && value.Access_Level === 'L2') {  
            notRequired.push('Regions', 'Plants');
        } else if (value && value.Access_Level === 'L3') {
            notRequired.push('Plants');
            let Flag = 'False';
            for (let i = 0; i < value.Plants.length; i++) {
                if ( value.Plants[i] === 'All') {
                    Flag = 'True';
                }
            }
            if (Flag === 'True') {
                value.Plants = []

                for (let i = 0; i < this.state.selectOptions.plantOptions.length; i++) {
                    if ( this.state.selectOptions.plantOptions[i].plantId != 'All') {
                        value.Plants.push(this.state.selectOptions.plantOptions[i].plantId)
                    }
                }
            }
        }
        if (isFormEmpty(value, notRequired)) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        if (!strongRegex.test(this.state.userInfo['Password']) && this.props.id !== 'Update Form') {
            toast.error('Invalid password. You password should match these conditions', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            }); 
            return;
        } 
        return this.props.onSubmit && this.props.onSubmit(value);
    }

    getOrgsList = async () => {
        const selectOptions = this.state.selectOptions;
        await getAllOrgs().then(res => {
            selectOptions.orgOptions = frameSelectOptions(res, 'orgId', 'orgName');
            this.setState({
                selectOptions
            });
        });
    }

    getRegionsListByOrg = async (org) => {
        const selectOptions = this.state.selectOptions;
        await getRegionsByOrg(org).then(res => {
            selectOptions.regionOptions = frameSelectOptions(res, 'regionId', 'regionName');
            this.setState({
                selectOptions
            });
        });
    }

    getPlantsListByRegion = async (regions) => {
        const {userInfo, selectOptions} = this.state;

        await getAllPlantsByMultipleRegions(userInfo.orgId, {Regions: regions}).then(res => {

            const plantsList = res?.data.data;
            let plantOptions: Array<any> = [];

            plantsList.forEach(plants => {
                plantOptions = _.concat(plantOptions, plants.plant);
            });

            if ( plantOptions.length === 0 ) {
                selectOptions.plantOptions = [];
                userInfo.Plants = [];
                this.setState({
                    userInfo,
                    selectOptions
                });
            }
            let newplantOptions;
            if(plantOptions[0].plantId !== null) {
                newplantOptions = [{plantId:'All', plantName:'All'}, ...plantOptions];
            }else{
                newplantOptions = [{plantId:null, plantName:null}, ...plantOptions];
            }

            selectOptions.plantOptions = frameSelectOptions(newplantOptions, 'plantId', 'plantName');
            const userPlants = JSON.parse(JSON.stringify(userInfo.Plants));
            _.forEach(userPlants, (plant) => {
                const plantOptionsIndex = _.findIndex(selectOptions.plantOptions, {value: plant});
                if (plantOptionsIndex === -1) {
                    _.pull(userPlants, plant);
                }
            });
 
            userInfo.Plants = userPlants;
            dispatch(actions.change(`${this.props.formModelName}.Plants`, userPlants));

            this.setState({
                userInfo,
                selectOptions
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getUserLevelOptions = (loggedInLevel) => {
        let userLevelOptions: Array<any> = [];

        if (this.props.id !== 'CreateForm' && (this.props.loggedInUserInfo.UniqueId === this?.props?.userInstance?.props.UniqueId)) {
            userLevelOptions.push({
                label: this?.props?.userInstance?.props.Access_Level,
                value: this?.props?.userInstance?.props.Access_Level
            });
            return userLevelOptions;
        }

        if (loggedInLevel === 'L1') {
            userLevelOptions.push({label: 'L1', value: 'L1'}, {label: 'L2', value: 'L2'});
        }
        if (loggedInLevel === 'L2') {
            userLevelOptions.push({label: 'L2', value: 'L2'}, {label: 'L3', value: 'L3'}, {label: 'L4', value: 'L4'});
        } else if (loggedInLevel === 'L3') {
            userLevelOptions.push({label: 'L4', value: 'L4'});
        }
        return userLevelOptions;
    }

    onFieldValueChange = (field) => {
        let userInfo = this.state.userInfo;
        userInfo[field.target.id] = field.target.value;
        this.setState({
            userInfo
        });
    }

    handleAccessLevel = async (obj) => {
        let {userInfo} = this.state;
        if (obj.value === 'L1') {
            dispatch(actions.reset(`${this.props.formModelName}.orgId`));
        }
        userInfo['Access_Level'] = obj.value;
        this.setState({
            userInfo
        });
    }

    handleOrgSelection = (obj) => {
        let {userInfo, selectOptions} = this.state;
        userInfo['orgId'] = obj.value;
        if (userInfo.Access_Level === 'L3') {
            this.getRegionsListByOrg(userInfo['orgId']);
        }
        this.setState({
            userInfo
        });
    }

    handleRegionSelect = async (obj) => {
        let userInfo = this.state.userInfo;
        userInfo['Regions'] = _.map(obj, 'value');
        if (userInfo.Access_Level === 'L4' || userInfo.Access_Level === 'L3') {
            this.getPlantsListByRegion(userInfo['Regions']);
        }
        this.setState({
            userInfo
        });
    }

    handlePlantSelect = (obj) => {
        let userInfo = this.state.userInfo;
        let AllSelArr: Array<any> = [];

        let Flag = 'False';
        for (let i = 0; i < obj.length; i++) {
            if ( obj[i].label === 'All') {
                Flag = 'True';
            }
        }

        if (Flag === 'True') {

            for (let i = 0; i < this.state.selectOptions.plantOptions.length; i++) {
                if ( this.state.selectOptions.plantOptions[i].label !== 'All') {
                    AllSelArr.push(this.state.selectOptions.plantOptions[i].value)
                    Flag = 'True';
                }
            }
            userInfo['Plants'] = AllSelArr;
        } else {
            userInfo['Plants'] = _.map(obj, 'value');
        }
        this.setState({
            userInfo
        });
    }

    onCancel = () => {
        let url = '/users/'
        if (this.props.id === 'Update Form'){
            url = '/profile/'

        } 
        if (this.state.userInfo.UniqueId == null) {
            this.props?.history?.push('/users');
        } else {
            this.props?.history?.push(url + this.state.userInfo.UniqueId);
        }
    }

    renderFormContent = () => {
        const {loggedInUserInfo} = this.props;
        const {
            fieldEnabler: {enableOrg, selectedLevel},
            selectOptions: {orgOptions, userLevelOptions, plantOptions, regionOptions},
            userInfo
        } = this.state;
        
        const loggedInLevel = this.props.loggedInUserInfo.Access_Level;
        return (
            <div className="user-form-controls">
                <React.Fragment>
                    <Form
                        model={`${this.props.formModelName}`}
                        onSubmit={this.handleFormSubmit}
                        className="">
                        {this.state.isLoading ? <Loader type="submission"/> : ''}
                        <RRFInput
                            key={3}
                            model=".UserId"
                            type="text"
                            placeholder="Enter user id..."
                            id="UserId"
                            label="User ID:"
                            onChange={this.onFieldValueChange}
                            defaultValue={userInfo.UserId}
                        />
                        {this.props.id !== 'Update Form' ?
                            [<div className="fgcontainer">
                                <div className='fgDiv'>
                                <RRFInput
                                    key={4}
                                    model=".Password"
                                    type="password"
                                    placeholder="Enter new password..."
                                    onChange={this.onFieldValueChange}
                                    id="Password"
                                    label="Password:"
                                />
                                </div>
                                <div className="fgDiv">
                                    <h6 className='forgotPasswordHint'>
                                    * Password should have at least one Uppercase Letter,
                                        one Lowercase character , one numeric character, 
                                        one special symbol and minimum length of password should be 8 character
                                    </h6>
                                </div>
                                </div>
                                ] : <span/>}
                        <RRFInput
                            model=".First_Name"
                            type="text"
                            placeholder="Enter first name..."
                            id="First_Name"
                            label="First Name:"
                            onChange={this.onFieldValueChange}
                            defaultValue={userInfo.First_Name}
                        />
                        <RRFInput
                            model=".Middle_Name"
                            type="text"
                            placeholder="Enter middle name..."
                            id="Middle_Name"
                            label="Middle Name:"
                            onChange={this.onFieldValueChange}
                            defaultValue={userInfo.Middle_Name}
                        />
                        <RRFInput
                            model=".Last_Name"
                            type="text"
                            placeholder="Enter last name..."
                            id="Last_Name"
                            label="Last Name:"
                            onChange={this.onFieldValueChange}
                            defaultValue={userInfo.Last_Name}
                        />
                        {this.props.id === 'CreateForm' || (this.props.id === 'Update Form' && (loggedInLevel === 'L1' || loggedInLevel ==='L2')) ? [<RRFInput
                            model=".Email"
                            type="email"
                            placeholder="Enter email address..."
                            id="Email"
                            label="Email:"
                            onChange={this.onFieldValueChange}
                            defaultValue={userInfo.Email} 
                        />]:'' }
                        <RRFInput
                            model=".Access_Level"
                            type="dropdown"
                            placeholder="Select access level..."
                            id="Access_Level"
                            disabled={this.props.userInstance && (this.props.loggedInUserInfo.UniqueId === this.props.userInstance.props.UniqueId)}
                            multi={false}
                            menuItems={this.state.selectOptions.userLevelOptions}
                            label="Access Level:"
                            defaultValue={userInfo.Access_Level}
                            onSelect={this.handleAccessLevel}
                        />
                        {this.props.loggedInUserInfo.Access_Level === 'L1' && (this.state.userInfo.Access_Level !== 'L1') &&
                        < RRFInput
                            model=".orgId"
                            type="dropdown"
                            placeholder="Select organisation..."
                            id="orgId"
                            menuItems={orgOptions}
                            disabled={(this.props.userInstance && this.props.loggedInUserInfo.UniqueId === this.props.userInstance.props.UniqueId) || this.state.userInfo.Access_Level !== 'L2'}
                            label="Organisation:"
                            defaultValue={userInfo.orgId}
                            onSelect={this.handleOrgSelection}
                            multi={false}
                        />}
                        {(this.state.userInfo.Access_Level !== 'L1' && this.state.userInfo.Access_Level !== 'L2') &&
                        <RRFInput
                            key={1}
                            model=".Regions"
                            type="dropdown"
                            placeholder="Select region..."
                            disabled={(this.props.userInstance && this.props.loggedInUserInfo.UniqueId === this.props.userInstance.props.UniqueId) || (this.state.userInfo.Access_Level !== 'L3' && this.state.userInfo.Access_Level !== 'L4')}
                            id="Regions"
                            menuItems={regionOptions}
                            onSelect={this.handleRegionSelect}
                            label="Region:"
                            defaultValue={userInfo.Regions}
                            multi={true}
                        />}
                        {(this.state.userInfo.Access_Level === 'L4' || this.state.userInfo.Access_Level === 'L3' ) && <RRFInput
                            key={2}
                            model=".Plants"
                            type="dropdown"
                            disabled={(this.props.userInstance && this.props.loggedInUserInfo.UniqueId === this.props.userInstance.props.UniqueId) || this.state.userInfo.Access_Level !== 'L4'}
                            placeholder="Select plants..."
                            id="Plants"
                            menuItems={plantOptions}
                            multi={true}
                            label="Plants:"
                            defaultValue={userInfo.Plants}
                            onSelect={this.handlePlantSelect}
                        />}
                        <RRFInput
                            model=".Job_Title"
                            type="text"
                            placeholder="Enter job title..."
                            id="Job_Title"
                            defaultValue={userInfo.Job_Title}
                            label="Job Title:"
                            onChange={this.onFieldValueChange}
                        />
                        <RRFInput
                            model=".Job_Desc"
                            type="text"
                            placeholder="Enter job description..."
                            id="Job_Desc"
                            label="Job Description:"
                            onChange={this.onFieldValueChange}
                            defaultValue={userInfo.Job_Desc}
                        />
                        <FormFooter onCancel={this.onCancel}/>
                    </Form>
                </React.Fragment>
            </div>
        );
    }

    render() {
        return <Async
            identifier="UserForm"
            promise={this.promise}
            loader={<Loader type="async"/>}
            error={<ErrorPage/>}
            content={this.renderFormContent()}
        />;
    }
}

export function mapStateToProps(state) {
    let loggedInUserInfo = null;
    if (state.login.get('token')) {
        loggedInUserInfo = parseJwt(state.login.get('token'));
    }
    const userId = state.login.get('UniqueId');
    return {  userId, loggedInUserInfo
    };
}

export const UserForm = withRouter(connect< IUserFormProps, any, any>(mapStateToProps)(UserFormImpl as any));
