import * as React from 'react';
import * as _ from 'lodash';
import {format} from 'date-fns'; 
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {InputRow} from './InputRow';
import {RDatePicker} from '../DatePicker';
import { weekdayIndexByDay } from '../../../utils/generalUtils';
import './AdvancedQuestionsRenderer.scss';
import { RRFInput } from './RRFInput';
import { UserModel } from '../../../model/UserModel';
import DatePicker from 'react-date-picker';

export interface IAdvancedQuestRendererProps {
    quest: any;
    localQuestNo: number | null;
    onDataUpdate?: Function;
    additionalInfo?: any;
}

export interface IAdvancedQuestRendererState {
    dateFieldValue: string | null;
    quest: any;
}

export class AdvancedQuestRenderer extends React.PureComponent<IAdvancedQuestRendererProps, IAdvancedQuestRendererState> {
    basicParams = JSON.parse(sessionStorage.getItem('inspectionDetails') || '{}');
    constructor(props: IAdvancedQuestRendererProps) {
        super(props);
        this.state = {dateFieldValue: null, quest: props.quest};
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.quest.answer !== nextProps.quest.answer) {
            this.state = {dateFieldValue: null, quest: nextProps.quest};
        } 
    }
    handleOptionSelection = (quest: any, data: any, fieldType: string, isSubQuest: boolean) => {
        const { additionalInfo: { dayofWeek: {weekDayName, weekdayIndex, ongoingWeek}, weekDayNameForFilter, monthofYear: {monthofYear, monthIndex, ongoingHalfYear}, yearRangeForFilter } } = this.props; 
        if(this.basicParams.parentId==='Daily-Weekly Check (All)80'  || this.basicParams.parentId === 'Daily-Weekly Check (additional)80') {
            let answers = quest.answer.split('~');
            if(answers.length>1) {
                let weekdayIndex = weekdayIndexByDay(weekDayNameForFilter); 
                answers[weekdayIndex] = fieldType !== 'Date' ? data : format(data, 'MM/dd/yyyy');
                quest.answer = answers.join('~'); 
            } else {
                quest.answer = fieldType !== 'Date' ? data : format(data, 'MM/dd/yyyy');
            }
        } else if(this.basicParams.parentId==='Yearly Check80') {
            let answers = quest.answer.split('~');
            if(answers.length>1) {
                let monthlyIndex = yearRangeForFilter===6?0:1;
                answers[monthlyIndex] = fieldType !== 'Date' ? data : format(data, 'MM/dd/yyyy');
                quest.answer = answers.join('~');
            } else {
                quest.answer = fieldType !== 'Date' ? data : format(data, 'MM/dd/yyyy');
            }
        } else {
            if(quest.answer!=='' && quest.answer === data) {
                quest.answer = '';
            } else {
                quest.answer = fieldType !== 'Date' ? data : format(data, 'MM/dd/yyyy');
            }
        }
        let dateFieldValue = JSON.parse(JSON.stringify(this.state.dateFieldValue));
        let question = JSON.parse(JSON.stringify(this.state.quest));
        if (!isSubQuest && question.subQuestions && question.subQuestions.length && question.answer !== question.answertoSQ) {
            dateFieldValue = null;
            question.subQuestions.forEach((sq: any) => {
                sq.answer = null;
            });
        }
        if (isSubQuest) {
            const indexOfQuest = _.findIndex(this.state.quest.subQuestions, {questionId: quest.questionId});
            if (indexOfQuest > -1) {
                question.subQuestions[indexOfQuest] = quest;
            }
        } else if (fieldType !== 'ObjectiveType') {
            question = quest;
        }

        this.setState({
            dateFieldValue: fieldType === 'Date' ? data : null,
            quest: question
        });

        if (this.props.onDataUpdate) {
            this.props.onDataUpdate(question, isSubQuest);
        }
    }
    handleDropdownSelection = async (data, id) => {  
        let ques = JSON.parse(JSON.stringify(this.state.quest));  
        this.handleOptionSelection(ques, data.value, 'Dropdown', false )
    }

    renderTitles = (quest: any) => {
        return (
            <div key={quest.title} className="field-heading">{quest.question}</div>
        );
    }

    renderSecondaryTitles = (quest: any) => {
        return (
            <div key={quest.title} className="secondary-heading">{quest.question}</div>
        );
    }

    renderHintTitles = (quest: any) => {
        return (
            // <div key={quest.title} className="secondary-heading">{quest.question}</div>
            <div key={quest.title} className="hint-heading" dangerouslySetInnerHTML={{ __html: quest.question }} />
        );
    }
    
    renderBlockScore = (quest: any, questNo: number | string, isSubQuest: boolean) => { 
        return (
            <div key={quest.title} className="block-score"><span className='block-score-title'>{quest.question}:</span> <span className='block-score-answer'>{quest.answer || 0}</span></div>
        );
    } 
    renderTotalScore = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        return (
            <div key={quest.title} className="total-score"><span className='total-score-title'>{quest.question}:</span> <span className='total-score-answer'>{quest.answer || 0} %</span></div>
        );
    } 

    renderParagraph = (quest: any) => {
        let ques = quest.question.split('~')
        return (
            <div className="question-row">
                <div key={quest.questionId} className="field-paragraph"> 
                    {
                        (ques || []).map( (item) => {
                            return <p dangerouslySetInnerHTML={{__html: item}}
                            />
                        })
                    }
                </div> 
            </div>
        )
    }

    renderDropdown = (quest: any, localQuestNo: number | string, isSubQuest: boolean) => { 
        let menuItems: any = [];
        if(quest.question==='Plant Leadperson' || quest.question==='Shop Leadperson') {
            let usersInstance = UserModel.list(); 
            if(usersInstance.length>0) {
                usersInstance.forEach((element: any) => {
                    menuItems.push({'label': element.props.First_Name, 'value': element.props.UniqueId})
                });
            }
        } 
        if(quest.question==='Shop Number - Name') { 
            if(quest.questionHint.length>0) {
                quest.questionHint.forEach((element: any) => {
                    menuItems.push({'label': element, 'value': element})
                });
            }
        } 
        return (
            <React.Fragment>
                <div className="question-row">
                    <div className="question">
                        {localQuestNo && <div className="question-no">{localQuestNo}</div>}
                        {quest.question} {quest.questionHint && quest.question!=='Shop Number - Name'?<span className='question-hint'>
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ <Tooltip id={`TooltipQuestionHint-${localQuestNo}`}> <span>STANDARD - Explained</span> </Tooltip>}>
                                { <span style={{ opacity: 1 }} ><i className="fa fa-info-circle" aria-hidden="true"></i> {quest.questionHint}</span> }
                            </OverlayTrigger>  
                        </span> :''}
                    </div>
                    <div className='inspection-question-dropdown'>  
                        <RRFInput
                            model={`.${quest.questionId}`}
                            type="dropdown"
                            placeholder={`Select ${quest.question}`}
                            id={`${quest.questionId}`} 
                            onSelect={this.handleDropdownSelection}
                            label={null}  
                            defaultValue={quest.answer} 
                            menuItems={menuItems}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderObjectiveTypeQuestions = (quest: any, localQuestNo: number | string, isSubQuest: boolean) => { 
        let { ParentId, date } = this.basicParams;

        return (
            <React.Fragment>
                <div className="question-row">
                    <div className="question">
                        <div className="question-no">{localQuestNo}</div>
                        {quest.question} {quest.questionHint?<span className='question-hint'>
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ <Tooltip id={`TooltipQuestionHint-${localQuestNo}`}> <span>STANDARD - Explained</span> </Tooltip>}>
                                { <span style={{ opacity: 1 }} ><i className="fa fa-info-circle" aria-hidden="true"></i> {quest.questionHint}</span> }
                            </OverlayTrigger>  
                        </span> :''}
                    </div>
                    { (ParentId!=='Daily-Weekly Check (All)80' && ParentId !== 'Daily-Weekly Check (additional)80' && ParentId !== 'Yearly Check80') && <div className={`buttons`}>
                        { quest.choices && quest.choices.length > 0 && quest.choices.map((btn: string, index: number) => (
                            <button
                                key={index}
                                onClick={() =>
                                    this.handleOptionSelection(quest, btn, 'ObjectiveType', isSubQuest)}
                                type="button"
                                className={(quest.answer === btn) ? 'button-yes'
                                    : 'button-no'}
                            >{btn}</button>
                        ))}
                    </div> }
                    { (ParentId==='Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80') && this.renderDailyWeeklyMultiChoices(quest, localQuestNo, isSubQuest) }
                    { (ParentId==='Yearly Check80') && this.renderYearlyMultiChoices(quest, localQuestNo, isSubQuest) }
                </div> 
                {quest.subQuestions && quest.subQuestions.length > 0 && quest.subQuestions.map((subQuest: any, index: number) => {
                    if(subQuest.answerSelected==quest.answer) {
                        return (<div key={localQuestNo + '.' + (index + 1)} className={'levelSubQuestIndent question-row'}>{this.generateQuestion(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                    }
                })}
            </React.Fragment>
        );
    }
    renderDailyWeeklyMultiChoices = (quest: any, localQuestNo: number | string, isSubQuest: boolean) => { 
        const { additionalInfo: { dayofWeek: {weekDayName, weekdayIndex, ongoingWeek}, weekDayNameForFilter } } = this.props; 
        let weekdayIndexbyday = weekdayIndexByDay(weekDayNameForFilter);
        let answers = quest.answer.split('~');  
        return <div className={`buttons multiple-choices`}>
            { quest.choices && quest.choices.length > 0 && quest.choices[0]!=='Weekly' && quest.choices.map((btn: string, index: number) => (
                <button
                    key={index}
                    onClick={() =>
                        index === weekdayIndexbyday && this.handleOptionSelection(quest, btn, 'ObjectiveType', isSubQuest)}
                    type="button"
                    className={ (answers[index] === btn )?( (index===weekdayIndexbyday) ? 'button-yes':'button-otherdayyes non-answerable')
                        : (ongoingWeek && weekdayIndex<index?'button-ongoingWeek non-answerable ':(index===weekdayIndexbyday?'button-no':'button-no non-answerable' ) )}
                >{btn}</button>
            ))}
            { quest.choices && quest.choices.length > 0 && quest.choices[0]==='Weekly' &&
                <button
                key={1}
                onClick={() => this.handleOptionSelection(quest, 'Weekly', 'ObjectiveType', isSubQuest)}
                type="button"
                className={ (answers[0] === 'Weekly' )?'button-yes':'button-no'}
            >Weekly</button> }
        </div>;
    }

    renderYearlyMultiChoices  = (quest: any, localQuestNo: number | string, isSubQuest: boolean) => {
        const { additionalInfo: { monthofYear: {monthofYear, monthIndex, ongoingHalfYear}, yearRangeForFilter } } = this.props;  
        let monthofYearIndexByHalfYear = yearRangeForFilter===6?0:1; 
        let answers = quest.answer.split('~');  
        return <div className={`buttons multiple-choices`}>
            { quest.choices && quest.choices.length > 0 && quest.choices[0]!=='Annual' && quest.choices.map((btn: string, index: number) => (
                <button
                    key={index}
                    onClick={() =>
                        index === monthofYearIndexByHalfYear && this.handleOptionSelection(quest, btn, 'ObjectiveType', isSubQuest)}
                    type="button" 
                    className={ `yearly-button ${( (answers[index] === btn )?( (index===monthofYearIndexByHalfYear) ? 'button-yes':'button-otherdayyes non-answerable')
                        : (ongoingHalfYear && monthIndex<index?'button-ongoingWeek non-answerable ':(index===monthofYearIndexByHalfYear?'button-no':'button-no non-answerable' ) ) ) }`}
                >{btn}</button>
            ))}
            { quest.choices && quest.choices.length > 0 && quest.choices[0]==='Annual' &&
                <button
                key={1}
                onClick={() => this.handleOptionSelection(quest, 'Annual', 'ObjectiveType', isSubQuest)}
                type="button"
                className={ (answers[0] === 'Annual' )?'button-yes':'button-no'}
            >Annual</button> }
        </div>;
    }

    renderComments = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        let { ParentId, date } = this.basicParams;
        let answer = '';
        const { additionalInfo: { dayofWeek: {weekDayName, weekdayIndex, ongoingWeek}, weekDayNameForFilter, monthofYear: {monthofYear, monthIndex, ongoingHalfYear}, yearRangeForFilter  } } = this.props; 
        if(ParentId==='Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80') {
           let answers = quest.answer.split('~');
           let weekdayIndexbyday = weekdayIndexByDay(weekDayNameForFilter);
           answer = answers.length>1?answers[weekdayIndexbyday]:answers[0];
        } else if(ParentId==='Yearly Check80') {
            let answers = quest.answer.split('~');
            let monthofYearIndexByHalfYear = yearRangeForFilter===6?0:1; 
            answer = answers.length>1?answers[monthofYearIndexByHalfYear]:answers[0];
        } else {
            answer = quest.answer;
        }
        return (<div className={'question'}>
            <InputRow
                label={quest.question}
                quesNo={questNo}
                type={quest.questionType==='Text'?'text':"textarea"}
                placeholder=""
                onChange={(e) => this.handleOptionSelection(quest, e.target.value, 'Comments', isSubQuest)}
                defaultValue={answer}
                key={quest.questionId}
            />
        </div>);
    } 

    renderTime = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        return ( 
            <div className="question"> 
                <InputRow
                    label={quest.question}
                    quesNo={questNo}
                    type="time" 
                    onChange={(e) => this.handleOptionSelection(quest, e.target.value, 'Comments', isSubQuest)}
                    defaultValue={quest.answer}
                    key={quest.questionId}
                />
            </div> 
        );
    }

    renderDate = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        let { ParentId, date } = this.basicParams;
        let answer = '';
        const { additionalInfo: { dayofWeek: {weekDayName, weekdayIndex, ongoingWeek}, weekDayNameForFilter, monthofYear: {monthofYear, monthIndex, ongoingHalfYear}, yearRangeForFilter, repairCompleteMinDate, repairMaxDate } } = this.props; 
        if(ParentId==='Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80') {
           let answers = quest.answer.split('~');
           let weekdayIndexbyday = weekdayIndexByDay(weekDayNameForFilter); 
           answer = answers.length>1?answers[weekdayIndexbyday]:answers[0];
           this.setState({
                dateFieldValue: answer, 
            }); 
        } else if(ParentId==='Yearly Check80') {
            let answers = quest.answer.split('~');
            let monthofYearIndexByHalfYear = yearRangeForFilter===6?0:1; 
            answer = answers.length>1?answers[monthofYearIndexByHalfYear]:answers[0];
            this.setState({
                 dateFieldValue: answer, 
             });  
        } else {
            answer = quest.answer;
            this.setState({
                dateFieldValue: answer, 
            }); 
        } 
        return (
            <div>
                <div className="question-row">
                    <div className="question">
                        {questNo ? <div className="question-no">{questNo}</div> : ''}
                        <div className="show-grid input-container row">
                            <div className="input-label col"><label key="">
                                {quest.question}</label>
                            </div>
                            <div className="input-label col">
                                {/* <RDatePicker
                                    value={answer ? new Date(answer || new Date()): null}
                                    maxDate={new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000))}
                                    minDate={repairCompleteMinDate || ''}
                                    onChange={(e: any) => {
                                        this.handleOptionSelection(quest, e, 'Date', isSubQuest);
                                    }}   
                                    questionId={quest.questionId}
                                /> */}
                                <DatePicker
                                    maxDate={repairMaxDate? new Date(repairMaxDate): new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000))}
                                    minDate={repairCompleteMinDate ? new Date(repairCompleteMinDate): undefined}
                                    value={answer ? new Date(answer): undefined}
                                    onChange={(e: any) => {
                                        this.handleOptionSelection(quest, e, 'Date', isSubQuest);
                                    }}
                                    locale="en-US"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    generateQuestion = (quest: any, questNo: number | string, isSubQuest: boolean) => {
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': {
                return this.renderSecondaryTitles(quest);
            }
            case 'Hint': {
                return this.renderHintTitles(quest);
            }
            case 'Paragraph': {
                return this.renderParagraph(quest);
            }
            case 'Dropdown': {
                return this.renderDropdown(quest, questNo, isSubQuest);
            }
            case 'ObjectiveType': {
                return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest);
            }
            case 'Comments':  
            case 'Text': 
                return this.renderComments(quest, questNo, isSubQuest); 
            case 'Date': {
                return this.renderDate(quest, questNo, isSubQuest);
            }
            case 'Time': {
                return this.renderTime(quest, questNo, isSubQuest);
            }
            case 'BlockScore': {
                return this.renderBlockScore(quest, questNo, isSubQuest);
            }
            case 'TotalScore': {
                return this.renderTotalScore(quest, questNo, isSubQuest);
            }
            default: {
                return <span/>;
            }
        }
    }

    render() {
        const {localQuestNo}: any = this.props;
        return this.generateQuestion(this.state.quest, localQuestNo, false);
    }
}
