import * as React from 'react';
import {actions} from 'react-redux-form';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {dispatch, frameSelectOptions, isFormEmpty} from '../../utils/generalUtils';
import * as _ from 'lodash';
import {toast} from 'react-toastify';

import {PlantModel} from '../../model/PlantModel';
import {BoxInput} from '../reusableComponents/FormComponents/BoxInput';
import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {Form} from '../reusableComponents/FormComponents/Form';
import {IHistory} from '../../interfaces';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import {getAllInspectionsByComp} from '../../services/plantService';
import {Loader} from '../reusableComponents/Loader';
import {UserModel} from '../../model/UserModel';
import {getAllOrgs} from '../../services/orgService';
import {PlantLocationMap} from './plantLocationMap';
import {parseJwt} from '../../services/loginService';
import {CircularProgress} from "material-ui";
import Dialog from 'react-bootstrap-dialog';

export interface IPlantFormProps {
    plantInstance?: PlantModel;
    history?: IHistory;
    model?: string;
    onSubmit?: (values) => void;
    userId?: any;
    loggedInUserInfo?: any;
}

export interface IPlantFormState {
    plantInfo: any;
    orgOptions: Array<any>;
    regionOptions: Array<any>;
    showLoader: boolean;
    showFullLoader: boolean;
    isPlantAddressChanged: boolean;
    regionUpdateFlag: boolean;
}

export class PlantFormImpl extends React.PureComponent<IPlantFormProps, IPlantFormState> {
    userData = UserModel.getUserData();
    plantLocation: any;
    dialog: Dialog;

    constructor(props: IPlantFormProps) {
        super(props);

        this.handleInspectionFormChange = this.handleInspectionFormChange.bind(this);
        this.onFieldValueChange = this.onFieldValueChange.bind(this);

        this.state = {
            orgOptions: [],
            regionOptions: [],
            isPlantAddressChanged: false,
            plantInfo: {
                plantId: props.plantInstance ? props.plantInstance.props.plantId : null,
                plantName: props.plantInstance ? props.plantInstance.props.plantName : null,
                orgId: props.plantInstance ? props.plantInstance.props.orgId : this.userData.orgId,
                plantRegion: props.plantInstance ? props.plantInstance.props.plantRegion : null,
                street: props.plantInstance ? props.plantInstance.props.street : null,
                city: props.plantInstance ? props.plantInstance.props.city : null,
                state: props.plantInstance ? props.plantInstance.props.state : null,
                zip: props.plantInstance ? props.plantInstance.props.zip : null,
                country: 'USA',
                facilityType: props.plantInstance ? props.plantInstance.props.facilityType : null,
                inspections: props.plantInstance ? props.plantInstance.props.inspections : {},
                shapesList: props.plantInstance ? props.plantInstance.props.shapesList : [],
                coords: props.plantInstance ? props.plantInstance.props.coords : {lng: 0, lat: 0},
                outfallsList: props.plantInstance ? props.plantInstance.props.outfallsList : [],
                coordinatesPointerList: props.plantInstance ? props.plantInstance.props.coordinatesPointerList : [],
                regionUpdateFlag:false,
            },
            showLoader: false,
            showFullLoader: false,
            regionUpdateFlag: false
        };
        this.getOrgsList();
        dispatch(actions.change(`${props.model}.orgId`, this.props.plantInstance ? this.props.plantInstance.props.orgId : this.userData.orgId));
        dispatch(actions.change(`${props.model}.Country`, 'USA'));
    }

    getOrgsList = async () => {
        await getAllOrgs().then(res => {
            let regionsList = [];
            if (this.userData.Access_Level === 'L3') {
                regionsList = this.userData.Regions;
            } else if (this.userData.Access_Level === 'L2') {
                const orgRegions = _.find(res, {orgId: this.state.plantInfo.orgId});
                regionsList = orgRegions ? orgRegions.orgRegion : [];
            }
            this.setState({
                orgOptions: frameSelectOptions(res, 'orgId', 'orgName'),
                regionOptions: frameSelectOptions(regionsList, 'regionId', 'regionName'),
                regionUpdateFlag: false
            });
        });
    }

    handleInspectionFormChange = (input, freq, permitType, inputType) => {
        const plantInfo = JSON.parse(JSON.stringify(this.state.plantInfo));
        const inspectionForm = _.find(plantInfo.inspections[permitType][freq], {'FormId': input.FormId});
        if (inputType === 'form') {
            if (inspectionForm.IsSelected && inspectionForm.PointSources.length && _.filter(inspectionForm.PointSources, {IsSelected: true}).length > 0) {
                inspectionForm.PointSources.map(ps => ps.IsSelected = false);
            }
            inspectionForm.IsSelected = !input.IsSelected;
        } else {
            _.find(inspectionForm.PointSources, {'PsId': input.PsId}).IsSelected = !input.IsSelected;
        }

        this.setState({
            plantInfo
        });
    }

    generateInspectionFormsList = async (plantInfo) => {
        await getAllInspectionsByComp(this.userData.orgId, plantInfo['facilityType']).then(res => {
            plantInfo.inspections = res?.data?.data;
            this.setState({
                plantInfo,
                showLoader: false
            });
            let message = res?.data?.message;
            if(message==='inspection not found') {
                toast.error('No inspection form found', {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'failed-toast'
                });
            }
        }).catch(err => {
            this.setState({
                showLoader: false
            });
        });
    }
    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }
    onregionChange = (field, id?: string) => {
        const plantInfo = JSON.parse(JSON.stringify(this.state.plantInfo));
        const plantRegion = this.props.plantInstance ? this.props.plantInstance.props.plantRegion : null;

        if (id) {
            plantInfo[id] = field.value;
        } else {
            plantInfo[field.target.id] = field.target.value;
        }
        
        this.setState({
            isPlantAddressChanged: true,
            plantInfo,
            regionUpdateFlag: true
        });
    }
    /* onregionChangeDefault = async (field, id?: string) => {
        this.getOrgsList();
        const plantInfo = JSON.parse(JSON.stringify(this.state.plantInfo));
        const plantRegion = this.props.plantInstance ? this.props.plantInstance.props.plantRegion : null;
        console.log('onregionChangeDefault ',plantRegion, ' id ',id)
        if (id) {
            plantInfo[id] = plantRegion;
        } else {
            plantInfo[field.target.id] = field.target.value;
        console.log('plantInfo ',plantInfo, ' - ',field.target.id)
        }
        // this.setState({plantInfo})
        await this.setState({
            isPlantAddressChanged: true,
            plantInfo
        });
        
        console.log('plantInfo ',plantInfo)
    } */
    onFieldValueChange = (field, id?: string) => {
        const plantInfo = JSON.parse(JSON.stringify(this.state.plantInfo));
        const plantRegion = this.props.plantInstance ? this.props.plantInstance.props.plantRegion : null;
        let regionUpdateFlag = false;

        if (id === 'plantRegion'  && this.props.model ===  'forms.updatePlantForm' && plantRegion !== field.value) {
            regionUpdateFlag = true;
            this.dialog.show({
                body: `Are you sure you want to change this plant's region. 
                        And if your doing so, all user's assigned to this plant will be 
                        unassigned and you have to assign them after updating this plant`,
                actions: [
                    Dialog.CancelAction(() => this.getOrgsList()),
                    Dialog.OKAction(() =>{ this.onregionChange(field, id)})
                ]
            });
        }
        if (id === 'plantRegion'  && this.props.model ===  'forms.updatePlantForm' && plantRegion === field.value) {
            this.setState({
                regionUpdateFlag: false
            });
        }

        if (regionUpdateFlag === false) {
            if (id) {
                plantInfo[id] = field.value;
            } else {
                plantInfo[field.target.id] = field.target.value;
            }
            if (id === 'facilityType') {
                plantInfo.inspections = {};
                this.setState({
                    plantInfo,
                    showLoader: true
                });
                setTimeout(() => {
                    this.generateInspectionFormsList(plantInfo);
                });
            } else {
                this.setState({
                    isPlantAddressChanged: true,
                    plantInfo
                });
            }
        }
    }

    onSubmitForm = async () => {
        if (isFormEmpty(this.state.plantInfo, ['inspections', 'shapesList', 'outfallsList', 'coordinatesPointerList', 'regionUpdateFlag'])) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        await this.setState({
            showFullLoader: true
        });
        const dataToSend = this.state.plantInfo;
        dataToSend.regionUpdateFlag = this.state.regionUpdateFlag;
        if (!dataToSend.coords) {
            dataToSend.coords = {lng: 0, lat: 0};
        }
        
        this.props.onSubmit && await this.props.onSubmit(dataToSend);
        await this.setState({
            showFullLoader: false
        });
    }

    renderHtmlContent = () => {
        if (this.state.showLoader) {
            return <span className="facility-loader-image">
                <CircularProgress size={150} color="#A4BF43" thickness={5}/>
            </span>
        }
        if (!_.isEmpty(this.state.plantInfo.inspections)) {
            return (['Water', 'Air', 'Waste', 'Others'].map((items, index) => (
                [
                    this.state.plantInfo.inspections[items] && (this.state.plantInfo.inspections[items].Daily.length || this.state.plantInfo.inspections[items].Monthly.length || this.state.plantInfo.inspections[items].Quarterly.length) ?
                        <Row key={index}>
                            <div className="permit-type-box col-12">Permit Type - {items}</div>
                            <BoxInput
                                label="Inspections"
                                permitType={items}
                                formsList={this.state.plantInfo.inspections[items]}
                                type="Inspections"
                                id="Insnew"
                                onClick={this.handleInspectionFormChange}
                            />
                        </Row> : null
                ]
            )));
        }
    }

    setMapCoordsData = (coords) => {
        const plantInfo = this.state.plantInfo;
        plantInfo.coords = coords;
        this.setState({
            plantInfo
        });
    }

    updateShapesList = (shapesList) => {
        const plantInfo = this.state.plantInfo;
        plantInfo.shapesList = shapesList;
        this.setState({
            plantInfo
        });
    }
    updateCoordinatesPointerList = (coordinatesPointerList) => {
        const plantInfo = this.state.plantInfo;
        plantInfo.coordinatesPointerList = coordinatesPointerList;
        this.setState({
            plantInfo
        });
    }

    updateSourceList = (outfallsList) => {
        const plantInfo = this.state.plantInfo;
        plantInfo.outfallsList = outfallsList;
        this.setState({
            plantInfo
        });
    }

    updateAddressChangeStatus = (status) => {
        this.setState({
            isPlantAddressChanged: false
        });
    }

    handleCancel = () => {
        this.props.history?.push('/maintenance');
    }

    gettingSelectValueOnLoad = (value) => {

    }

    render() {
        return <div className="plant-detail-container">
            {
            // @ts-ignore
            <Dialog className="confirmation-modal" ref={(el: any) => {
                this.dialog = el;
            }}/>}
            {this.state.showFullLoader ? <Loader key={1} type="submission"/> : ''}
            <Row>
                <Col className="plant-basic-data" xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form
                        model={this.props.model}
                        onSubmit={this.onSubmitForm}>
                        <RRFInput
                            model=".plantId"
                            type="text"
                            placeholder="Enter Plant id..."
                            id="plantId"
                            label="Plant ID:"
                            onChange={this.onFieldValueChange}
                            defaultValue={this.state.plantInfo.plantId}
                        />
                        <RRFInput
                            model=".plantName"
                            type="text"
                            placeholder="Enter Plant name..."
                            id="plantName"
                            label="Name:"
                            onChange={this.onFieldValueChange}
                            defaultValue={this.state.plantInfo.plantName}
                        />
                        {this.userData.Access_Level === 'L1' && <RRFInput
                            model=".orgId"
                            type="dropdown"
                            placeholder="Select Organisation..."
                            onSelect={this.onFieldValueChange}
                            id="orgId"
                            disabled={true}
                            menuItems={this.state.orgOptions}
                            label="Organisation:"
                            defaultValue={this.state.plantInfo.orgId}
                        />}
                        <RRFInput
                            model=".plantRegion"
                            type="dropdown"
                            placeholder="Select Plant Region..."
                            onSelect={this.onFieldValueChange}
                            id="plantRegion"
                            menuItems={this.state.regionOptions}
                            label="Region:"
                            defaultValue={this.state.plantInfo.plantRegion}
                        />
                        <RRFInput
                            model=".street"
                            type="text"
                            placeholder="Enter plant street..."
                            id="street"
                            onChange={this.onFieldValueChange}
                            label="Street:"
                            defaultValue={this.state.plantInfo.street}
                        />
                        <RRFInput
                            model=".city"
                            type="text"
                            placeholder="Enter plant city..."
                            id="city"
                            onChange={this.onFieldValueChange}
                            label="City:"
                            defaultValue={this.state.plantInfo.city}
                        />
                        <RRFInput
                            model=".state"
                            type="text"
                            placeholder="Enter plant state..."
                            id="state"
                            onChange={this.onFieldValueChange}
                            label="State:"
                            defaultValue={this.state.plantInfo.state}
                        />
                        <RRFInput
                            model=".zip"
                            type="text"
                            placeholder="Enter zip code..."
                            id="zip"
                            onChange={this.onFieldValueChange}
                            label="Zip:"
                            defaultValue={this.state.plantInfo.zip}
                        />
                        <RRFInput
                            model=".country"
                            type="text"
                            placeholder="Enter plant country..."
                            id="country"
                            disabled={true}
                            onChange={this.onFieldValueChange}
                            label="Country:"
                            defaultValue={'USA'}
                        />
                        <RRFInput
                            model=".facilityType"
                            type="dropdown"
                            placeholder="Select a Facility Type"
                            id="facilityType"
                            onSelect={this.onFieldValueChange}
                            menuItems={[{
                                label: 'Ready Mix', value: 'Ready Mix'
                            },
                                {
                                    label: 'Quarry', value: 'Quarry'
                                },
                                {
                                    label: 'Asphalt', value: 'Asphalt'
                                },
                                {
                                    label: 'Maintenance Shop', value: 'Maintenance Shop'
                                },
                                {
                                    label: 'Others', value: 'Others'
                                }
                            ]}
                            label="Facility Type"
                            defaultValue={this.state.plantInfo.facilityType}
                        />
                    </Form>
                </Col>
                <Col className="details-right-section" xs={12} sm={6} md={6} lg={6} xl={6}>
                    <PlantLocationMap plantInfo={this.state.plantInfo} type="edit"
                        isPlantAddressChanged={this.state.isPlantAddressChanged}
                        updateAddressChangeStatus={this.updateAddressChangeStatus}
                        updateCoords={this.setMapCoordsData} updateShapesList={this.updateShapesList}
                        updateSourceList={this.updateSourceList} updateCoordinatesPointerList={this.updateCoordinatesPointerList}/>
                </Col>
            </Row>
            <Row className="inspections-form-selector">{this.renderHtmlContent()}</Row>
            <Row className="form-footer">
                <button type="button" onClick={this.handleCancel} className="cancel-button">
                    Cancel
                </button>
                <button type="button" onClick={this.onSubmitForm} className="submit-button">
                    Submit
                </button>
            </Row>
        </div>;
    }
}

export function mapStateToProps(state) {
    let loggedInUserInfo = null;
    if (state.login.get('token')) {
        loggedInUserInfo = parseJwt(state.login.get('token'));
    }
    const userId = state.login.get('UniqueId');
    return {
        state, userId, loggedInUserInfo
    };
}

export const PlantForm = connect< IPlantFormProps, any, any>(mapStateToProps)(PlantFormImpl as any);
