import * as React from 'react';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import './pagination.scss';
import { BaseModel } from '../../../model/BaseModel';
import { setFilters } from '../../../utils/generalUtils';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { IHistory, IForms } from '../../../interfaces';
declare var require: any;
const queryString = require('query-string'); 


export interface IPaginationProps {
    totalCount: number | undefined;
    Model?: typeof BaseModel;
    promise: (pageNumber: any) => void;
    history?: IHistory;
    identifier: string;
    forms?: IForms;
    pageNum?: number;
}

export class Pagination extends React.Component<IPaginationProps, { pageNumber?: number, activeBar: any, showLoader?: boolean }> {
 
    handlePageNumberClick = async (pageNumber: number, activeBar: any) => {
        const { Model, promise, forms } = this.props;
        if (Model) {
            Model.deleteAll();
        }
        this.setState({ pageNumber, /* showLoader: true */ });
        
        // let urlParams = queryString.parse(this.props.history?.location.search);
        // let param: any = { pageNumber, activeBar, sortingIndex: urlParams.sortingIndex || '', sortingOrder: urlParams.sortingOrder || '', ...forms?.commonFilterForm };
        // this.props.history && setFilters(param, this.props.history );
        setLoading(this.props.identifier); 
        if (promise) {
            await promise(pageNumber);
        }
        //this.setState({ showLoader: false });
        setSuccess(this.props.identifier);
    }
    handleState() {
       /*  let activeBar = queryString.parse(this.props.history?.location.search).activeBar || 1;
        let pageNumber = queryString.parse(this.props.history?.location.search).pageNumber || 1; */
        /* const { search } = this.props.history?.location;
        const { activeBar, pageNumber } = queryString.parse(search);
        let activeBarValue = activeBar || 1;
        let pageNumberValue = pageNumber || 1; */
        this.state = {
            // activeBar: queryString.parse(this.props.history?.location.search).activeBar || 1,
            activeBar: this.props.pageNum || 1,
            pageNumber: this.props.pageNum || 1,
            // pageNumber: queryString.parse(this.props.history?.location.search).pageNumber || 1,
            showLoader: false,
        };
    
    }
    activeStyle: React.CSSProperties = {
        fontWeight: 'bold',
        pointerEvents: 'none',
        backgroundColor: '#a4bf43',
        color: 'white',
        cursor: 'pointer'
    };

    /* renderPageNumbers = (activeBar: any) => {
        const { totalCount } = this.props; 
        let pageNumberContainer: JSX.Element[] = [];
        let startpgno = Number(activeBar) - 3;
        let endpgno = Number(activeBar) + 3;
        if (activeBar > 7 ) {

        }
        // console.log(startpgno, endpgno, ' - ',(activeBar > 7 ))
        if ((totalCount || 0) > 21) {console.log('Number ',totalCount)
            for (let i = activeBar; i < Number(activeBar) + 21; i++) {
                pageNumberContainer.push(<div key={i}
                    className={`page-number`}
                    style={Number(this.state.pageNumber) === (i + 1) ? this.activeStyle : {}}
                    onClick={() => { this.handlePageNumberClick(i, this.state.activeBar); }}
                >
                    {i}
                </div>);
            }
            return pageNumberContainer;
        }console.log('Number 1 ',totalCount)
        for (let i = 0; i < Number(totalCount || 0); i++) {
            pageNumberContainer.push(<div key={i}
                className={`page-number`}
                style={Number(this.state.pageNumber) === (i + 1) ? this.activeStyle : {}}
                onClick={() => { this.handlePageNumberClick(i + 1, this.state.activeBar); }}
            >
                {i + 1}
            </div>);
        }
        return pageNumberContainer;
    } */

    renderPageNumbers = (activeBar: number) => {
        activeBar = +activeBar;
        // const { totalCount } = this.props; 02
        let pageNumberContainer: JSX.Element[] = [];
        // const totalPages = Math.ceil(totalCount / 20);
        const totalPages = Math.ceil(this.props.totalCount || 0 / 20);

        const startpgno = Math.max(activeBar - 2, 1);
        const endpgno = Math.min(activeBar + 2, totalPages);
        // console.log('endpgnos ',endpgno, ' - ',activeBar)
        // console.log('startpgno ', typeof(startpgno),'endpgnos ',typeof(endpgno), ' - ',typeof(activeBar), ' - ',typeof(totalPages))
        // console.log('startpgno ',startpgno, ' endpgno ',endpgno, ' totalPages ',totalPages, ' this.props.totalCount ',this.props.totalCount)
    
        // Render previous page numbers
        if (startpgno > 1) {
            pageNumberContainer.push(
                <div
                    key={1}
                    className={`page-number`}
                    onClick={() => { this.handlePageNumberClick(1, activeBar); }}
                >
                    1
                </div>
            );
            if (startpgno > 2) {
                pageNumberContainer.push(<div key="prevDots" className="dots">...</div>);
            }
        }
    
        // Render page numbers
        for (let i = startpgno; i <= endpgno; i++) {
            pageNumberContainer.push(
                <div
                    key={i}
                    className={`page-number ${i === activeBar ? 'active' : ''}`}
                    style={Number(this.state.pageNumber) === (i) ? this.activeStyle : {}}
                    onClick={() => { this.handlePageNumberClick(i, activeBar); }}
                >
                    {i}
                </div>
            );
        }
    
        // Render next page numbers
        // console.log('endpgno < totalPages ', endpgno,'-',totalPages,(endpgno < totalPages),' (endpgno < totalPages - 1) ', (endpgno < totalPages - 1))
        if (endpgno < totalPages) {
            if (endpgno < totalPages - 1) {
                pageNumberContainer.push(<div key="nextDots" className="dots">...</div>);
            }
            pageNumberContainer.push(
                <div
                    key={totalPages}
                    className={`page-number`}
                    onClick={() => { this.handlePageNumberClick(totalPages, activeBar); }}
                >
                    {totalPages}
                </div>
            );
        }
    
        return pageNumberContainer;
    }

    render() {
        this.handleState();
        const totalPages = Math.ceil(this.props.totalCount || 0 / 5);
        return <div className={`pagination-container`}>
            {this.state.showLoader && <SubmissionLoader /> }
            <div className="bar-container" >
                {/* {totalPages > 10 &&
                    <div
                        className="start"
                        onClick={() =>
                            this.setState({ activeBar: this.state.activeBar === 1 ? 1 : this.state.activeBar - 10 })}
                    >
                        <i className="left" />1
                </div>} */}
                {this.renderPageNumbers(this.state.activeBar)}
                {/* {totalPages > 10 &&
                    < div
                        className="end"
                        onClick={() =>
                            this.setState({ activeBar: Number(this.state.activeBar) + 10 })}
                    >
                        <i className="right" />{totalPages}</div>
                } */}
            </div>
        </div >;
    }
}
