import {Alert} from '../reusableComponents/Alert';
import {actions} from 'react-redux-form';
import {dispatch} from '../../utils/generalUtils';
import {SubmissionLoader} from '../reusableComponents/SubmissionLoader';
import {Form} from '../reusableComponents/FormComponents/Form';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import {getGoldenRules, submitGoldenRules} from '../../services/miscellaniousService';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Button} from '../reusableComponents/FormComponents/Button';
import {BasePage} from '../reusableComponents/BasePage';
import {Async} from '../reusableComponents/Async';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Loader} from '../reusableComponents/Loader';
import {IHistory, IRules} from '../../interfaces';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './goldenRules.scss';
import {withRouter} from 'react-router-dom';
import {UserModel} from '../../model/UserModel';
import {v4 as uuidv4} from 'uuid';

export interface IGoldenRulesProps {
    show?: boolean;
    showModal?: boolean;
    history?: IHistory;
    showEditForm?: boolean;
    instance: any;
    referenceFiles: any;
}

export interface IGoldenRulesState {
    showModal: boolean;
    editRules: boolean;
    totalRules: { [key: number]: IRules };
    newRules: { [key: number]: IRules };
}

export class GoldenRulesImpl extends React.Component<IGoldenRulesProps, IGoldenRulesState> {

    constructor(props: IGoldenRulesProps) {
        super(props);
        this.state = {
            showModal: props.show || false, editRules: false,
            totalRules: {}, newRules: {}
        };
    }

    promise = async () => {
        let goldenRules: any = await getGoldenRules();
        // goldenRules.forEach((ruleData, index) => {
        //     dispatch(actions.change(`forms.goldenRules.rule[${index}]`, ruleData.rule));
        //     dispatch(actions.change(`forms.goldenRules.priority[${index}]`, +ruleData.priority));
        // });)
        goldenRules = goldenRules.sort((a: any, b: any) => a.priority - b.priority);
        goldenRules = goldenRules.reduce((res, elem) => {
            res[elem.ruleNum] = elem;
            return res;
        }, {});

        this.setState({
            totalRules: goldenRules,
            newRules: goldenRules,
            editRules: false,
        }, () => this.incrementRows());
        return null;

    };

    getChildContext() {
        return {formModel: 'forms.goldenRules'};
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    toggleRulesForm = () => {
        const {editRules, newRules, totalRules} = this.state;
        if (editRules) {
            this.setState({
                totalRules: newRules
            });
        }
        this.setState({
            editRules: !editRules
        });
        // totalRules.forEach((ruleData, index) => {
        //     dispatch(actions.change(`forms.goldenRules.rule[${index}]`, ruleData.rule));
        //     dispatch(actions.change(`forms.goldenRules.priority[${index}]`, +ruleData.priority));
        // });
    };

    toggleDropdownVisibility = () => {
        this.setState({
            showModal: false
        });
    };

    incrementRows = () => {

        const newUniqueKey: any = uuidv4();  // Date.now().toString()
        const totalRules = {...this.state.totalRules, [newUniqueKey]: {ruleNum: newUniqueKey, rule: '', priority: ''}};
        this.setState({
            totalRules: totalRules
        });
    };

    decrementRow = (index: number) => {
        const {totalRules} = this.state;
        // dispatch(actions.remove(`forms.goldenRules.rule`, index));
        delete totalRules[index];
        this.setState({
            totalRules: totalRules
        });
    };

    renderDynamicTable = () => {
        const {totalRules} = this.state;
        if (Object.values(totalRules).length > 0) {
            return Object.values(totalRules).map((ruleData, index) => {
                return <Row key={index} className="show-grid">
                    <Col style={{padding: '10px 15px', display: 'inline-block'}} xs={9} sm={9} className="input">
                        <RRFInput
                            type="text"
                            model={`.${ruleData.ruleNum}_rule`}
                            id={`.${ruleData.ruleNum}_rule`}
                            defaultValue={ruleData.rule}
                        />
                    </Col>
                    <Col style={{padding: '10px 15px', display: 'inline-block'}} xs={2} sm={2} className="input">
                        <RRFInput
                            type="number"
                            model={`.${ruleData.ruleNum}_priority`}
                            id={`.${ruleData.ruleNum}_priority`}
                            pattern="[0-9]*"
                            defaultValue={ruleData.priority}
                        />
                    </Col>
                    <Col style={{padding: '10px 15px', display: 'inline-block'}} xs={1} sm={1} className="input">
                        <i className="fa fa-trash-o" onClick={() => this.decrementRow(ruleData.ruleNum)} aria-hidden="true"/>
                    </Col>
                </Row>;
            });
        }
        return <Row key={0} className="show-grid">
            <Col style={{padding: '10px 15px', display: 'inline-block'}} xs={9} sm={9} className="input">
                <RRFInput
                    type="text"
                    model={`.rule[${0}]`}
                    id={`.rule[${0}]`}
                />
            </Col>
            <Col style={{padding: '10px 15px', display: 'inline-block'}} xs={2} sm={2} className="input">
                <RRFInput
                    type="number"
                    model={`.priority[${0}]`}
                    id={`.priority[${0}]`}
                />
            </Col>
        </Row>;
    };

    handleSubmit = async (goldenRules: any) => {

        let {totalRules} = this.state;
        for (let goldenRule in goldenRules) {
            const [ruleNum, fieldType] = goldenRule.split('_');
            totalRules[ruleNum] = { ...totalRules[ruleNum], [fieldType]: goldenRules[goldenRule]};
        }
        const {history} = this.props;
      
        for (let goldenRule in totalRules) {
            if (totalRules[goldenRule]['priority'] == '' && totalRules[goldenRule]['rule'] != '') {
                totalRules[goldenRule]['priority'] = '999'
            }
        }

        let {response, updatedRules} = await submitGoldenRules(Object.values(totalRules).filter(e => e.rule && e.priority), history);
        if (response) {
            updatedRules = updatedRules.sort((a: any, b: any) => a.priority - b.priority);
            updatedRules = updatedRules.reduce((res, elem) => {
                res[elem.ruleNum] = elem;
                return res;
            }, {});
            this.setState({
                totalRules: updatedRules || {},
                newRules: updatedRules || {},
                editRules: false
            }, () => this.incrementRows());

            return response;
        }
    };
    renderContent = () => {
        const {showEditForm} = this.props;
        const {totalRules, editRules, newRules} = this.state;
        if (!totalRules) {
            return <div/>;
        }
        if (editRules) {
            return <div>
                <Form {...SubmissionLoader} onSubmit={this.handleSubmit} model="forms.goldenRules">
                    <Row key={-1} className="show-grid">
                        <Col style={{padding: '10px 0px', display: 'inline-block', color: 'white', textAlign: 'center', fontSize: '16px'}} xs={9} sm={9} className="input">
                            Rules
                        </Col>
                        <Col style={{padding: '10px 0px', display: 'inline-block', color: 'white', textAlign: 'center', fontSize: '16px'}} xs={2} sm={2} className="input">
                            Priority
                        </Col>
                        <Col style={{padding: '10px 0px', display: 'inline-block', color: 'white', fontSize: '16px'}} xs={1} sm={1} className="input">
                            Action
                        </Col>
                    </Row>
                    {this.renderDynamicTable()}
                    <Alert id="golden-rules-failed" className="danger-alert"/>
                    <div className="table-buttons">
                        <button
                            className="add-row"
                            type="button"
                            onClick={this.incrementRows}>
                            <i className="fa fa-plus" aria-hidden="true"/>
                            &nbsp;Add Another Rule
                        </button>
                    </div>

                    <div  style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} className="edit-button form-button">
                        <Button style={{
                            color: 'white',
                            border: '1px solid #a4bf43',
                            backgroundColor: '#000000', width: '100px', margin: '50px',
                            borderRadius: '10px', height : '40px', fontSize: '12px',
                            display: 'inline-block'
                        }} onClick={async () => {
                            this.toggleRulesForm();
                        }} type="reset">Cancel</Button>
                        <Button style={{
                            color: 'white',
                            width: '100px', margin: '50px',
                            borderRadius: '10px', height : '40px', fontSize: '12px',
                            border: '10px solid #a4bf43',
                            display: 'inline-block',
                            backgroundColor: '#a4bf43',
                            float: 'right',
                        }}
                                type="submit">Update</Button>
                    </div>
                </Form>
            </div>;
        }

        return <div className="rules-list">
            {Object.values(newRules).length <= 0 ? 'No Rules Found' : Object.values(newRules).map(
                (ruleData, index) => {
                    return <p key={index}>{index + 1}. {ruleData.rule} </p>;
                }
            )}
            <Alert id="golden-rules-success" className="success-alert"/>
            <div className="table-buttons">
                <button
                    className="add-row"
                    type="button"
                    onClick={() => this.toggleRulesForm()}>
                    <i className="fa fa-plus" aria-hidden="true"/>
                    &nbsp;Add Another Rule
                </button>
            </div>
        </div>;
    };

    render() {
        return (
            <BasePage pageTitle="Golden Rules">
                <div className="golden-rules">
                    <Async
                        identifier="GoldenRules"
                        promise={this.promise}
                        loader={<Loader/>}
                        error={<ErrorPage/>}
                        content={this.renderContent()}
                    />
                </div>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    let userId = state.login.get('userId');
    const instance = UserModel.get(userId);
    return {
        instance,
        referenceFiles: state.miscellaneous.get('referenceFiles')
    };
}

export const GoldenRulesPage =
    withRouter(connect<IGoldenRulesProps, any, any>(mapStateToProps)(GoldenRulesImpl as any));
