import './maintenance.scss';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {IHistory, ISelectOptions} from '../../interfaces';
import {PlantModel} from '../../model/PlantModel';
import {parseJwt} from '../../services/loginService';
import {getAllPlantsByRegion} from '../../services/plantService';
import {BasePage} from '../reusableComponents/BasePage';
import {ListPage} from '../reusableComponents/ListPage';
import {RegionFilter} from '../reusableComponents/RegionFilter';
import {ListItem} from './ListItem';
import { RegionModel } from '../../model/OrgModel';
import { getRegionsByOrg } from '../../services/orgService';
import { Pagination } from '../reusableComponents/Pagination';

const queryString = require('query-string');

export interface IMaintenanceProps {
    instances: Array<PlantModel>;
    history?: IHistory;
    activityOptions?: ISelectOptions[];
    loggedInUserInfo: any;
    userId: string;
    location?: {
        search: string;
    }; 
    axiosPreviousToken: any;
    regionList: [];
}

export interface IMaintenanceState {
    filterBy: string;
    isAsc: boolean;
    sortIndex: any;
    searchText: any;
    curPageNo :any;
}

export class MaintenanceImpl extends React.Component<IMaintenanceProps, IMaintenanceState> {
    constructor(props: IMaintenanceProps) {
        super(props);
        let region = 'All';
        if (this.props.location?.search) {
            region = queryString.parse(this.props.location.search).region;
        }
        this.state = {
            filterBy: region,
            isAsc: true,
            sortIndex: 'plantId',
            curPageNo : 1,
            searchText: ''
        };
    }

    promise = async () => {
        if (PlantModel.list().length <= 1) {
            await getAllPlantsByRegion(this.props.userId, this.state.filterBy,  this.state.isAsc, this.state.sortIndex);
        }
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.props.loggedInUserInfo.orgId)
        }
    }

    renderAddPlantsButton = () => {
        return <div className="add-button" onClick={() => this.props.history?.push('/maintenance/create')}>
            Add Plant <i className="fa fa-plus-square" aria-hidden="true"/>
        </div>;
    }

    getFilteredInstances = async (filterName, value) => {
        this.props.history?.push(`/maintenance?region=${value}`);
        await this.setState({filterBy: value,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1
        });
        await getAllPlantsByRegion(this.props.userId, value,  this.state.isAsc, this.state.sortIndex);
    }
    
    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            curPageNo: searchText.length > 0 && this.state.searchText === searchText  ? this.state.curPageNo : 1,
            searchText: searchText
        })
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, this.state.curPageNo, searchText, this.props.axiosPreviousToken);
    }
    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, curPageNo, this.state.searchText, this.props.axiosPreviousToken);

    }

    render() {
        let totalCount = this.props.instances[0]?.props.TotalCount ? this.props.instances[0]?.props.TotalCount : 20
        let pageno = Math.ceil(totalCount/20 );

        return (
            <BasePage
                pageTitle="Maintenance"
                className="list-page-base"
                sideButton={this.props.loggedInUserInfo.Access_Level === 'L2' ? this.renderAddPlantsButton() : <span/>}
            >
                <ListPage
                    // @ts-ignore
                    identifier="PlantListPage"
                    instances={this.props.instances}
                    listItemComponent={ListItem}
                    searchBy={['PlantId', 'plantName', 'plantRegionName', 'state', 'Active']}
                    instanceOf={'plantInfo'}
                    pageHeadings={['#', 'Plant ID', 'Plant Name', 'Region', 'State', 'Status']}
                    listItemFields={['', 'plantId', 'plantName', 'plantRegionName', 'state', 'Active']}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    cols={[1, 2, 3, 2, 2, 2]}
                    searchPlaceHolder="Enter plant id, plant name, region, state or status to filter..."
                    emptyListMessage="No Plants found"
                    chartFilter={this.props.loggedInUserInfo.Access_Level !== 'L1' ? 
                        <RegionFilter
                            selectedValue={this.state.filterBy}
                            onChange={this.getFilteredInstances}
                            regionList={this.props.regionList}
                        /> : <span/>}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const instances = PlantModel.list();
    const userId = state.login.get('UniqueId');
    let loggedInUserInfo = null;
    if (state.login.get('token')) {
        loggedInUserInfo = parseJwt(state.login.get('token'));
    }
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');

    const regionList = RegionModel.list();
    return {
        userId, instances, loggedInUserInfo, regionList, axiosPreviousToken
    };
}

export const Maintenance = withRouter(connect< IMaintenanceProps, any, any>(mapStateToProps)(MaintenanceImpl as any));
