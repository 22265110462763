import '../Reports/reports.scss';

import * as React from 'react';
import {Col, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {hideUSTModal} from '../../actions/modalActions';
import {IDailyForms, IHistory} from '../../interfaces';
import {PlantModel} from '../../model/PlantModel';
import {getAllPlantsByRegion} from '../../services/plantService';
import {BasePage} from '../reusableComponents/BasePage';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {ListPage} from '../reusableComponents/ListPage';
import {ListFooter} from './ListFooter';
import {ListItem} from './ListItem';
import { Pagination } from '../reusableComponents/Pagination';
import axios from 'axios';
import { saveAxiosPreviousToken } from '../../actions/miscellaneousActions';
import { dispatch } from '../../utils/generalUtils';
import moment from 'moment';
const queryString = require('query-string');


export interface IInspectionsProps {
    instances: PlantModel[];
    dailyForms?: IDailyForms;
    showUSTModal: boolean;
    formType: string;
    history?: IHistory;
    userId: string;
    selectedDate: string;
    formInstance: PlantModel; 
    axiosPreviousToken: any;
    location?: any;
}

export interface IInspectionsState {
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
}

export class InspectionsImpl extends React.Component<IInspectionsProps, IInspectionsState> {

    componentDidMount() {
        // this.props.history.push('/inspections');
    }
    constructor(props: IInspectionsProps) {
        super(props);
        this.state = {
            isAsc: true,
            sortIndex: 'plantId',
            curPageNo: 1,
            searchText:''
        };
    }

    promise = async () => {
        let pageNumber = 1;

        if (this.props.location.search) {
            pageNumber = queryString.parse(this.props.location.search).pagenum;
        }
        // await getAllPlantsByRegion(this.props.userId);
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, pageNumber, this.state.searchText, this.props.axiosPreviousToken);
        await this.setState({
            curPageNo: pageNumber,
        })

    }

    handleFooterYes = () => {
        const {formInstance: {props: {plantId, plantRegion}}, selectedDate, formType} = this.props;
        this.props.history?.push(`/monthly-checklist-form/${formType}/${plantId}/${plantRegion}/${selectedDate}`);
        hideUSTModal();
    }

    handleFooterNo = () => {
        const {formInstance: {props: {plantId, plantRegion}}, selectedDate, formType} = this.props;
        this.props.history?.push(`/monthly-checklist-form/${formType}/${plantId}/${plantRegion}/${selectedDate}`);
        hideUSTModal();
    }

    renderModal = () => {
        return <Modal
            show={this.props.showUSTModal}
            onHide={() => hideUSTModal()}
            className="argos-modal"
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    Select Form Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Is this a corrective action follow-up form?
            </Modal.Body>
            <Modal.Footer>
                <FormFooter
                    submitLabel="Yes"
                    cancelLabel="No"
                    onSubmit={this.handleFooterYes}
                    onCancel={this.handleFooterNo}
                />
            </Modal.Footer>
        </Modal>;
    }
    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            curPageNo: searchText.length > 0 && this.state.searchText === searchText  ? this.state.curPageNo : 1,
            searchText: searchText
        })
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, this.state.curPageNo, searchText, this.props.axiosPreviousToken);
    }
    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, curPageNo, this.state.searchText, this.props.axiosPreviousToken);
        /* const {history: {push}}: any = this.props;

        let currTab = this.props.history?.location.pathname;
        console.log('currTab Insp index',currTab)
        let urls: string = currTab+'?pageno='+curPageNo;
        push(urls); */
    }
    render() {
        const {instances} = this.props;
        let totalCount = instances[0]?.props.TotalCount ? instances[0]?.props.TotalCount : 20
        let pageno = Math.ceil(totalCount/20 );
        return (
            <BasePage className="list-page-base" pageTitle={this.props.history?.location.pathname == '/housekeeping' ? 'Housekeeping Inspections' : "Env'l Inspections"}>
                {this.renderModal()}
                <ListPage
                    // @ts-ignore
                    identifier="PlantListPage"
                    height="71vh"
                    instances={instances}
                    listItemComponent={ListItem}
                    pageNum={this.state.curPageNo}
                    searchBy={['PlantId', 'plantName', 'plantRegionName', 'state', 'facilityType']}
                    instanceOf={'plantInfo'}
                    pageHeadings={['#', 'Plant ID', 'Plant Name', 'Region', 'State', 'Facility Type']}
                    listItemFields={['', 'plantId', 'plantName', 'plantRegionName', 'state', 'facilityType']}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    sort={[{'heading': 'Plant Name', 'sortBy': 'plantName'}]}
                    cols={[1, 2, 3, 2, 2, 2]}
                    searchPlaceHolder="Enter plant id, plant name, region, state or facility type to search..."
                    emptyListMessage="No plants found"
                    listFooter={ListFooter}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const instances = PlantModel.list(state);
    const showUSTModal = state.modal.get('showUSTModal');
    const formType = state.modal.get('formType');
    const formInstance = state.modal.get('formInstance');
    const selectedDate = state.modal.get('selectedDate');
    const userId = state.login.get('UniqueId');
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');
    // axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    // console.log('axiosPreviousToken INSP ',state.miscellaneous.get('axiosPreviousToken'))
    return {
        userId, instances, showUSTModal, formType, selectedDate, formInstance, axiosPreviousToken
    };
}

export const Inspections =
    withRouter(connect< IInspectionsProps, any>(mapStateToProps)(InspectionsImpl as any));
