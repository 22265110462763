import './loginPage.scss';

import {CircularProgress} from 'material-ui';
import {MuiThemeProvider} from 'material-ui/styles';
import * as React from 'react';
import {connect} from 'react-redux';
import {actions, Control, Form} from 'react-redux-form';
/* import {withRouter} from 'react-router-dom'; */ 
import { Link, withRouter } from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {IHistory} from "../../interfaces";
import {setLoading, setSuccess} from '../../actions/loadingActions';
import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {loginUser, submitForgotPassword} from '../../services/loginService';
import {dispatch, isFormEmpty} from '../../utils/generalUtils';
import {Async} from '../reusableComponents/Async';
import logo from '../../images/logo_.png';
import bgImage from '../../images/bg_.jpg';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';

export interface ILoginPageProps {
    history: IHistory;
    pathName: string;
    isLoggedIn: boolean;
    expiryCheck: boolean;
    email: any;
}

export interface ILoginFormImplState {
    email: string;
    forgotPasswordClicked: boolean;
    orgsList: Array<any>;
    isOrgList: boolean;
    selOrgId: '';

}

export class LoginPageImpl extends React.PureComponent<ILoginPageProps, ILoginFormImplState> {

    constructor(props: ILoginPageProps) {
        super(props);

        this.state = {email: '', forgotPasswordClicked: false, orgsList: [], isOrgList: false,selOrgId: ''};
    }

    promise = async () => {
        return null;
    }

    componentDidMount() {

        if (this.props.isLoggedIn) {
            this.props.history.push('/home');
        }
        document.title = 'Login To EnviroConnect';
    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    handleSubmit = async (values) => {
        const {pathName, history} = this.props;
        setLoading('LoginButton');
        if (isFormEmpty(values)) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            setSuccess('LoginButton');
            return;
        }
        if (!this.state.selOrgId) {
            this.setState({
                isOrgList: false
            });
        }
        let orgList = await loginUser(values.UserId, values.Password, this.props.history, pathName, this.state.selOrgId);
        setSuccess('LoginButton');

        if (orgList != undefined) {
            this.setState({
                isOrgList: true,
                orgsList: orgList
            });
        }
        setSuccess('LoginButton');
        return false;
    }
    hideOrgDropdown = async () => {
        this.setState({
            isOrgList: false,
            selOrgId: ''
        });
    }
    handlePasswordResetSubmit = async () => {
        const requestData = {
            email: this.state.email,
            OrganizationId: this.state.selOrgId
        };

        const esubmittedValues = {email: requestData.email};
        setLoading('LoginButton');
        if (isFormEmpty(esubmittedValues)) {
            toast.error('Please enter your email to continue...', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            setSuccess('LoginButton');
            return;
        }
        const {props: {history}} = this;
        const fpresult = await submitForgotPassword(requestData, history); 

        if (fpresult != undefined && typeof(fpresult) == 'object') {
            this.setState({
                isOrgList: true,
                orgsList: fpresult
            });
        }
        // return false;
        if (fpresult === false) {
            setSuccess('LoginButton');
        }
        // this.setState({email: ''});
    }

    handleResetPwdBackBtn = () => {
        this.setState({email: '', forgotPasswordClicked: false});
        dispatch(actions.change(`forms.passwordResetLinkForm.userId`, ''));
    }

    render() {
        return [
            <ToastContainer key={0}/>,
            <img key={1} alt={'bg-img'} src={bgImage} className="background" height="300" width="300"/>,
            <div key={2} className="dark-theme"/>,
            <div key={3} className="login-page">
                {!this.state.forgotPasswordClicked ? [
                        <div className="left-section" key={8}>
                            <h1>
                                <img alt={'brand-logo'} src={logo}/>
                                <br/>
                                Welcome To <br/>
                                EnviroConnect <br/>
                                System
                            </h1>
                        </div>,
                        <div className="right-section" key={9}>
                            <h1 className="login-text">
                                <img alt={'brand-logo'} src={logo} className='responsive-content'/>
                                Login to EnviroConnect
                            </h1>
                            <div className="form-section">
                                <Form model="forms.loginForm" onSubmit={this.handleSubmit}>
                                    <div className="control-container">
                                        { // eslint-disable-next-line
                                            <Control.text
                                                key={4}
                                                model=".UserId"
                                                className="login-input"
                                                placeholder="Username"
                                                type="text" 
                                                onChange={this.handleEmailChange}
                                                onClick={this.hideOrgDropdown}
                                            />}
                                    </div>
                                    <div className="control-container">
                                        { // eslint-disable-next-line
                                            <Control.text
                                                key={5}
                                                model=".Password"
                                                className="login-input"
                                                placeholder="Password"
                                                type="password"
                                                onChange={this.hideOrgDropdown}
                                            />}
                                    </div>
                                    {this.state.isOrgList && 
                                    <div className="control-container Org-Dropdown">{
                                            <RRFInput
                                            model=".orgId"
                                            type="dropdown"
                                            placeholder="Select Organisation..."
                                            id="orgId"
                                            className="org-lister "
                                            // onChange={e => this.setState({selOrgId: e.target.value})}
                                            // onChange={(e, value) => this.setState({
                                            //     selOrgId: value
                                            // })}
                                            onSelect={value => {
                                                this.setState({selOrgId: value['value']});
                                            }}
                                            // onSelect={this.selOrgDetails}
                                            menuItems={this.state.orgsList}
                                            label=""
                                            // defaultValue={'None'}
                                        />
                                    }</div>
                                    }
                                    <Async
                                        identifier="LoginButton"
                                        promise={() => setSuccess('LoginButton')}
                                        error={
                                            <div className="button-container">
                                                <button key={6} className="login-button">
                                                    Login
                                                </button>
                                            </div>
                                        }
                                        loader={
                                            <div className="button-container">
                                                <button className="login-button-loading">
                                                    <MuiThemeProvider>
                                                        <CircularProgress thickness={5} color="#a4bf43"/>
                                                    </MuiThemeProvider>

                                                </button>
                                            </div>
                                        }
                                        content={<div className="button-container">
                                            <button className="login-button">
                                                Login
                                            </button>
                                        </div>
                                        }

                                    />
                                    <div className="button-container">
                                        <span className="forget-button" onClick={() => {
                                            dispatch(actions.reset(`loginForm`));
                                            this.setState({email: ''}, () => {
                                                this.setState({
                                                    forgotPasswordClicked: true,
                                                    isOrgList: false,
                                                    orgsList: []
                                                });
                                            });
                                        }}>
                                            Forget password 
                                        </span>
                                        {/* <div className='forgot-password-help' ><i className="fa fa-question-circle" aria-hidden="true">&nbsp;</i>  Need help?</div> */}
                                        <div className="forgot-password-help" ><a target="_blank" href="https://enviroconnect-beta.s3.amazonaws.com/reference-files/6be6285f-b5ce-428e-9e3d-461f4cf5ebf0_Wsjw.docx"><i className="fa fa-question-circle" aria-hidden="true">&nbsp;</i>  Need help?</a></div>

                                    </div>
                                </Form>
                            </div>
                        </div>] :
                    <div key={5} className="password-reset-mail-page">
                        <h1 className="login-text">
                            <img alt={'brand-logo'} src={logo} className='responsive-content'/>
                            Password Reset
                        </h1>
                        <h4 className="reset-pwd-caption">
                            Please enter your email here to reset the password
                        </h4>
                        <div className="form-section">
                            <Form model="forms.passwordResetLinkForm" onSubmit={this.handlePasswordResetSubmit}>
                                <div className="control-container">
                                    { // eslint-disable-next-line
                                        <Control.text
                                            key={7}
                                            model=".userId"
                                            className="login-input"
                                            placeholder="Email Id"
                                            type="text" 
                                            onChange={this.handleEmailChange}
                                            onClick={this.hideOrgDropdown}
                                            defaultValue={''}
                                        />}
                                </div>
                                {this.state.isOrgList && 
                                    <div className="control-container Org-Dropdown">{
                                            <RRFInput
                                            model=".orgId"
                                            type="dropdown"
                                            placeholder="Select Organisation..."
                                            id="orgId"
                                            className="org-lister"
                                            // onChange={e => this.setState({selOrgId: e.target.value})}
                                            // onChange={(e, value) => this.setState({
                                            //     selOrgId: value
                                            // })}
                                            onSelect={value => {
                                                this.setState({selOrgId: value['value']});
                                            }}
                                            // onSelect={this.selOrgDetails}
                                            menuItems={this.state.orgsList}
                                            label=""
                                            // defaultValue={'None'}
                                        />
                                    }</div>
                                }
                                <Async
                                    identifier="LoginButton"
                                    promise={() => setSuccess('LoginButton')}
                                    error={
                                        <div className="button-container">
                                            <button className="login-button">
                                                Login
                                            </button>
                                        </div>
                                    }
                                    loader={
                                        <div className="button-container">
                                            <button className="login-button-loading">
                                                <MuiThemeProvider>
                                                    <CircularProgress thickness={5} color="#a4bf43"/>
                                                </MuiThemeProvider>
                                            </button>
                                        </div>
                                    }
                                    content={<div className="button-container">
                                        <button className="link-request-button">
                                            Continue
                                        </button>
                                        <button className="back-button" onClick={this.handleResetPwdBackBtn}>
                                            Back
                                        </button>
                                    </div>
                                    }
                                />
                            </Form>
                        </div>
                    </div>}
            </div>
        ];
    }
}

export function mapStateToProps(state, ownProps) {
    let pathName = '/home';

    if (ownProps.location.search) {
        pathName = ownProps.location.search.split('=')[1];
    }
    return {
        isLoggedIn: state.login ? state.login.get('isLoggedIn') : false,
        pathName
    };
}

/* export const LoginPage = withRouter(connect(mapStateToProps)(LoginPageImpl as any)); */
export const LoginPage = withRouter(connect(mapStateToProps)(LoginPageImpl)); 

