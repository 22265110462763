import {toast} from 'react-toastify'; 
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { asyncForEach, base64ToFile, deleteImageFromS3, detailsReportPagePdfMake, getAnswerWithFlag, getBase64, 
    getCurrentUserUUID, toDataUrl, uploadImageToS3 } from '../utils/generalUtils';
import {FORM_SUBMISSION_SUCCESS, SUBMISSION_FAILED, spiLogoBase64, flaggedBase64, checkboxBase64, FORM_DELETION_SUCCESS, FORM_INSPECTIONNAME_SUCCESS, HOUSEKEEPINGSCORES} from '../constants/generals';
import {getDev, postDev, putDev} from '../utils/HTTPDev';
import {format} from 'date-fns';
import {IHistory} from "../interfaces"; 

export async function getQuestions(formType: string) {
    try {
        const response = await getDev(`/getquestions/${formType}`);
        return response?.data.data;
    } catch (error) {
        throw error;
    }
}

export async function getCommonFormQuestions(formId: string, statusNotIn: string = '') {
    try {
        let response: any =  '';
        if(statusNotIn==='') {
            response = await getDev(`/questiontoforms/${formId}`);
        } else {
            response = await getDev(`/questiontoforms/${formId}/${null}/${statusNotIn}`);
        }
        return response?.data.data;
    } catch (e) {
        throw e;
    }
}

export async function deleteInspectionForms(formId: string, history?: IHistory) {
    try {
        let response: any =  '';
        // if(statusNotIn==='') {
            response = await postDev(`/deleteinspectionform/`+formId,'');
            await toast.success(FORM_DELETION_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            onClose: () => history?.push('/inspection-types')
        });
        // } else {
        //     response = await getDev(`/questiontoforms/${formId}/${null}/${statusNotIn}`);
        // }
        // return response?.data.data;
    } catch (e) {
        throw e;
    }
}
 

export async function saveCommonFormAnswers(data, inspectionId, history?: IHistory) {
    let response: any = null;
    try {
        if (inspectionId) {
            response = await postDev('/updateinspection/' + inspectionId, data);
        } else {
            response = await postDev('/submitinspection', data);
        }
        let redirUrl = '/housekeeping'
        if (data['inspectionType'] === 'Inspections') {
            redirUrl = '/inspections'
        }
        
        if (response && response?.data && response?.data.data && response?.data.status === 'valid') {
            toast.success(FORM_SUBMISSION_SUCCESS, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                onClose: () => history?.push(redirUrl)
            });
        } else {
            setTimeout(() => {
                history?.push(redirUrl);
              }, 14000);
        }

        return response;
    } catch (e) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
    }
}

export async function getCommonInspectionFormAnswers(formId, inspectionId, Page) {
    try {
        const response = await getDev(`/getanswerdetails/` + formId + `/` + inspectionId + `/` + Page);
        return response?.data.data;
    } catch (e) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
    }
}

export async function uploadImages(base64Array: string[], dirName: string) {
    const S3_URL = 'https://enviroconnect.s3.us-west-2.amazonaws.com';
    const images: Array<any> = [];
    const start = async () => {
        await asyncForEach(base64Array, async (base64 : any) => { 
            if (base64) {
                images.push(base64);
            } 
            /* if (base64.indexOf('https://enviroconnect.s3.us-west-2.amazonaws.com') === -1) {
                const fileName = `${uuidv1()}.png`;
                const {file, filename} = await base64ToFile(base64, fileName);
                const s3object = await uploadImageToS3(file, filename, dirName);
                if (s3object) {
                    images.push({imageURL: `${S3_URL}/${dirName}/${filename}`, dirPath: dirName, fileName});
                }
            } else if (base64) {
                images.push(base64);
            } */
        }); 
        return images;
    };
    return start();
}

export async function deleteImages(fileName: string[], dirName: string) {
    const images: Array<any> = [];
    const start = async () => {
        await asyncForEach(fileName, async (file) => {
            const s3object: any = await deleteImageFromS3(file, dirName);
            images.push(s3object.location);
        });
        return images;
    };
    return start();
}

export function imageExists(image_url) {
    let http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status !== 404;
}

export async function submitTechnicalFeedback(data, history?: IHistory) {
    try {
        const response = await postDev('/feedback', {
            feedback: data.comments,
            userId: getCurrentUserUUID()
        });

        toast.success(response?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'success-toast'
        });
        setTimeout(() => {
            history?.push('/home');
        }, 2000);
    } catch (error) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
        throw error;
    }
}

// tslint:disable-next-line:max-line-length
export async function prepareTable(answers, questions, title: string | null, colWidth: number, formType: string) {  
    let basicParams = JSON.parse(sessionStorage.getItem('inspectionDetails') || '{}'); 
    let dateRow: Array<any> = [{text: `${formType}/Question`, style: 'tableHeader'}];
    const finalData: Array<any> = [];
    const widths = [100];
    const heights = [30];
    const orgLogoRaw = localStorage.getItem('L1OrgLogo');
    let imgData = '';
    let logos: any = [];
    //let imgData = spiLogoBase64;
   /*  await toDataUrlTEST(orgLogoRaw, (imgDatas) => {
        imgData = imgDatas 
    })  */
    
    if(orgLogoRaw) { 
        imgData = await getBase64(orgLogoRaw); 
        if(imgData) {
            imgData = 'data:image/png;base64,' + imgData; 
            logos.push({
                image: imgData,
                fit: [100, 50],
                width: '82%'
            })
        }
    }  

    logos.push({
        image: spiLogoBase64,
        fit: [100, 50],
        width: '18%'
    }); 

    answers.forEach((val, index) => {
        if (formType === 'Weeks') {
            return dateRow.push({text: `${val.WeekDate}`, style: 'tableHeader'});    
        } else if (formType === 'Years') {
            return dateRow.push({text: `${val.Year}`, style: 'tableHeader'});    
        } else {
            return dateRow.push({text: `${index + 1}`, style: 'tableHeader'});
        }
    });

    finalData.push(dateRow); 

    questions.forEach((question, index) => {

        if(question.questionType!=='Paragraph' && question.questionType!=='Hint' ) {

            const dataRow = [{text: question.question}];
            answers.forEach((ans, i) => {
                // const answerSet = Object.assign.apply({}, ans.questionAndAnswer[question.questionId]); 
                // const answerSet = ans.questionAndAnswer; 

                let formattedSubmittedDate: string | null = null;

                if (ans.Date) {
                    formattedSubmittedDate = format(new Date(ans.Date.replace(/-/g, '/')), 'MM-dd-yyyy')
                }
                
                // answerSet['formSubmittedDate'] = {answer: formattedSubmittedDate};
                // ans.questionAndAnswer[question.questionId]['formSubmittedDate'] = {answer: formattedSubmittedDate};

                if (i === 0) {
                    widths.push(colWidth);
                    heights.push(30);
                }
                //const answerToPush = (formType === 'Days' && question.questionType === 'Comments') ? '' : answerSet[question.questionId];
                let answer: any = '';
                let isFlagged = false; 
                if( ans.questionAndAnswer[question.questionId] ) {
                    answer = ans.questionAndAnswer[question.questionId]['answer'];
                    let answerToShow: any = ''; 
                    if(basicParams.ParentId==='Housekeeping Plant80' || basicParams.ParentId==='Housekeeping Shop80') {
                        answerToShow = answer ? (HOUSEKEEPINGSCORES.includes(answer) ? HOUSEKEEPINGSCORES.indexOf(answer) + 1 : answer): ''; 
                        answer = answerToShow;
                    }
                    if(basicParams.ParentId==='Daily-Weekly Check (All)80' || basicParams.ParentId==='Daily-Weekly Check (additional)80') { 
                        if(answer) {
                            let answers = answer.split('~');
                            if(answers.length>0) { 
                                let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                                if(answers.length<2) {
                                    answerToShow = (answers[0]!==''? answers[0] :'')
                                } else {
                                    answers.map( (item, index) => {  
                                        answerToShow += (item!=='' ?  (days[index] + '-' + item + ','):''
                                    )
                                    });
                                } 
                            } 
                        }
                        answer = answerToShow;
                    } 
                    if(basicParams.ParentId==='Yearly Check80') { 
                        if(answer) {
                            let answers = answer.split('~');
                            if(answers.length>0) { 
                                let days = ['6 Month', '12 Month']
                                if(answers.length<2) {
                                    answerToShow = (answers[0]!==''? answers[0] :'')
                                } else {
                                    answers.map( (item, index) => {   
                                        answerToShow += item!=='' ?(days.includes(item) ? (days[index]+ ', '):(days[index] + ' - '+ item) ):'' ;
                                    
                                    });
                                } 
                            } 
                        }
                        answer = answerToShow;
                    } 
                    
                    isFlagged = ans.questionAndAnswer[question.questionId]['isFlagged'];
                    if(isFlagged) { 
                        let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(ans.questionAndAnswer[question.questionId]); 
                        answer = {
                            columns: [
                                { image: followUpClass, height: 10, width: 10, },
                                {
                                stack: [
                                    {
                                    columns: [
                                        { text: answer, width: '100%', margin: [1, 0, 0, 0], },
                                    ],
                                    },
                                ],
                                width: '*',
                                },
                            ],
                            }
                    }
                } 
                const answerToPush: any = (formType === 'Days' && question.questionType === 'Comments') ? '' : (
                    isFlagged?answer:answer
                ); 
                dataRow.push(answerToPush);
            });
            finalData.push(dataRow);
        }
    }); 

    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    finalData[0].forEach((ansData) => {
        widths.push(colWidth);
        heights.push(30);
    });

    const docDefinition = {
        pageOrientation: 'landscape',
        content: [ 
            { columns: logos },
            {text: title, style: 'header'},
            {
                style: 'checklistTable',
                table: {
                    widths,
                    body: finalData
                }
            }
        ],
        images: {
            followUpRemarkComplete: checkboxBase64, 
            followUpRemarkUnComplete: flaggedBase64, 
        },
        'styles': {
            'header': {
                'fontSize': 18,
                'bold': true,
                'margin': [
                    0,
                    0,
                    0,
                    10
                ]
            },
            'checklistTable': {
                'fontSize': 8,
                'textAlign': 'center',
                'margin': [
                    0,
                    0,
                    0,
                    0
                ]
            },
            'tableHeader': {
                'bold': true,
                'fontSize': 10,
                'color': 'black'
            }
        }
    };
    pdfMake.createPdf(docDefinition).download(`${title}.pdf`);
    //});
}

export async function pdfMaker(instance: any, region: string, plantId: string, date: string, userName: string) {
    const firstSectionData = instance.questions.slice(0, 38);
    const secondSectionData = instance.questions.slice(38);
    let values = detailsReportPagePdfMake(firstSectionData);
    let secondSectionvalues = detailsReportPagePdfMake(secondSectionData);
    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    const orgLogoRaw = localStorage.getItem('L1OrgLogo');
    toDataUrl(orgLogoRaw, (imgData) => {
        const docDefinition = {
            pageOrientation: 'portrait',
            content: [
                {
                    columns: [
                        {
                            image: imgData,
                            fit: [100, 50],
                            width: '90%'
                        },
                        {
                            image: spiLogoBase64,
                            fit: [100, 50],
                            width: '10%'
                        }
                    ]
                },
                {
                    text: 'Plant Preventive Form Details'
                    , fontSize: 8,
                    bold: true,
                    margin: [0, 5, 0, 5]
                },
                {
                    text: `Region: ${region} \t Plant Id: ${plantId} \t Date: ${date} \t Inspected By: ${userName}`
                    , fontSize: 8,
                    bold: true,
                    margin: [0, 5, 0, 5]
                },
                {

                    columns: [values.values, secondSectionvalues.values],
                },
                {text: `Notes:`, fontSize: 8, bold: true, margin: [0, 5, 0, 5]},
                {
                    table: {
                        widths: ['100%'],
                        headerRows: 1,
                        body: [
                            [
                                {text: ` ${values.notes} ${secondSectionvalues.notes}`, fontSize: 8},
                            ]
                        ],
                    },
                }
            ]
        };
        pdfMake.createPdf(docDefinition).download(`PlantPreventive.pdf`);
    });
}

export async function questionSetUp(data, history?: IHistory) {
    let response: any = null;
    try { 
        response = await postDev('/questionSetUp', data); 
        await toast.success(FORM_SUBMISSION_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            onClose: () => history?.push('/inspection-types')
        });
        //history.push('/inspections')
        return response;
    } catch (e) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
    }
}

export async function submitInspectionTypeForm(data, history?: IHistory) {  
    let response: any = null;
    try {  
        response = await postDev('/insertinspectionstype', data);   
        if(response.data.status==='valid') {
            await toast.success(FORM_SUBMISSION_SUCCESS, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                onClose: () => history?.push('/inspection-types')
            }); 
            return true;
        } 
        return false;
    } catch (e) {
        toast.error(SUBMISSION_FAILED, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'failed-toast'
        });
    }
}

export async function updateInspectionFormName(inspectionName: string, formId: string, history?: IHistory) {
    try {
        let response: any = await postDev(`/updateinspectionformname`, {formId, inspectionName });
        if(response.data.status==='valid') {
            await toast.success(FORM_INSPECTIONNAME_SUCCESS, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                /* onClose: () => history?.push('/inspection-types') */
            }); 
            return true;
        } 
        return false;
    } catch (e) {
        throw e;
    }
}

export async function getPriorFormDetails(PlantId: string, FormId: string, ReqDate: any) {
    try {
        let response: any = await postDev(`/getPriorFormDetails`, {
            FormId, ReqDate, PlantId }); 
        return response?.data.formDetails;
    } catch (e) {
        throw e;
    }
} 
